<div class="modal-body">
  <h3 *ngIf="title">{{title}}</h3>
  <!--<h3 *ngIf="title">{{title | translate}}</h3>-->
  <div *ngIf="body" class="spacer-md" [innerHTML]="body"></div>

  <div class="spacer-sm">
    <kh-input [controlValue]="inputControl"></kh-input>
  </div>

  <div class="text-center khModalButtons">
      <button class="btn btn-primary" (click)="onFirstButtonClick()" *ngIf="firstButtonText">{{firstButtonText}}</button>
      <button class="btn btn-default ml-1" (click)="onSecondButtonClick()" *ngIf="secondButtonText">{{secondButtonText}}</button>
      <!--<button class="btn btn-primary" (click)="onFirstButtonClick()" *ngIf="firstButtonText">{{firstButtonText | translate}}</button>
      <button class="btn btn-default ml-1" (click)="onSecondButtonClick()" *ngIf="secondButtonText">{{secondButtonText | translate}}</button>-->
  </div>
</div>
