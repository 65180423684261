import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TextEditorType } from 'app/question/constants/text-editor-type';
import ImageBlot from 'app/shared/components/input-text-editor/image-blot';
import { UploadFileInfoModel } from 'app/shared/modals/text-editor-file-upload-modal/upload-file-info.model';
import { TEMPLATE_PREFIX } from 'env/locale-config';
import { SelectionChange } from 'ngx-quill/lib/quill-editor.component';
import { QuillModules } from 'ngx-quill';
import Quill, { DeltaStatic } from 'quill';
import ImageResize from 'quill-image-resize-module';

Quill.register('modules/imageResize', ImageResize);
Quill.register({'formats/imageBlot': ImageBlot});

@UntilDestroy()
@Component({
  selector: 'kh-input-text-editor',
  styleUrls: ['./input-text-editor.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'input-text-editor.component.html',
  encapsulation: ViewEncapsulation.None
})

export class InputTextEditorComponent {
  public text = '';
  @Input() public imagePath = '';
  @Input() public editorMode: string = TextEditorType.TextArea;
  @Input() public float: string = TextEditorType.AlignedToLeft;
  @Output() public questionTextChange = new EventEmitter<string>();
  public range: null | { index: number, length: number } = null;
  public showUpload = false;
  public sourceImage: UploadFileInfoModel | null = null;
  public modules: QuillModules = {
    imageResize: {
      modules: ['Resize', 'DisplaySize']
    },
  };
  public isImageSelected = false;
  public editorFormat = 'html';
  private editor: Quill | null = null;

  @Input()
  public set questionText(text: string) {
    this.text = text;
  }

  public isTextarea(): boolean {
    return this.editorMode === TextEditorType.TextArea;
  }

  public getEditorClass(): Record<string, boolean> {
    return {
      'custom-editor': this.editorMode === TextEditorType.TextField,
      'float-from-right': this.float === TextEditorType.AlignedToRight,
      'textarea-editor': this.editorMode === TextEditorType.TextArea
    };
  }

  public changeValue(newData: string): void {
    this.questionTextChange.emit(newData);
  }

  public uploadClicked(event: any): void {
    this.sourceImage = null;
    this.showUpload = true;
    this.range = this.editor!.getSelection();

    if (this.range) {
      const text: DeltaStatic = this.editor!.getContents(this.range!.index, this.range!.length);

      if (text?.ops?.length) {
        const imageObj = text?.ops.find(item => !!item.insert.image);

        if (imageObj) {
          const image = imageObj.insert.image;

          this.sourceImage = {
            src: image.src,
            alt: image.alt,
            width: image.width,
            height: image.height,
            constrain: true
          } as UploadFileInfoModel;
        }
      }
    }
  }

  public hideModals(value: UploadFileInfoModel | null): void {
    this.showUpload = false;

    if (value) {
      if (this.range?.length) {
        this.editor?.deleteText(this.range!.index, this.range!.length);
      }

      const index = this.range?.index ?? 0;
      this.editor?.insertEmbed(index, 'image', value, 'user');
      this.sourceImage = null;
    }
  }

  public editorCreated(instance: Quill): void {
    this.editor = instance;
  }

  public selectionChanged(event: SelectionChange): void {
    this.isImageSelected = false;

    if (event.range) {
      const selection = event.editor!.getContents(event.range!.index, event.range!.length);
      const ops = selection.ops;
      this.isImageSelected = ops?.length === 1 && ops[0].insert?.image;
    }
  }

  public isCodeMode(): boolean {
    return this.editorFormat === 'text';
  }

  public changeEditorFormat(): void {
    this.editorFormat = this.editorFormat === 'html' ? 'text' : 'html';

    const text = this.editor!.clipboard.convert(this.text);

    if (this.editorFormat === 'html') {
      this.editor?.setContents(text, 'silent');
      return;
    }

    this.editor?.setText(this.text);
  }
}