import {
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { IScorable, ScorableData } from "../kh-manip-scorable/IScorable";
import {
  DynamicContentChild,
  OnDynamicData,
  OnDynamicMount,
} from "ngx-dynamic-hooks";
import { KhManipulativeRegionComponent } from "../kh-manip-scorable-region/kh-manip-scorable-region.component";
import { KhManipScorableIntInput } from "../kh-manip-scorable-int-input/kh-manip-scorable-int-input.component";
import { Inject } from '@angular/core';
import { QUESTION_EVENTS_SERVICE_TOKEN, IQuestionEventsService } from "../../../../services/IQuestionEvents";
import { ManipulativeRegionType } from "../../../enums/manipulative-region-type.enum";

@Component({
  selector: "kh-manipulative-clickable",
  templateUrl: "kh-manip-scorable-clickable.component.html",
  styleUrls: ["kh-manip-scorable-clickable.component.less"],
})
export class KhManipulativeClickableComponent  implements IScorable, OnDynamicMount {
  @Input() public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @Input("frame") public frame = "0 0 100 100";
  @Input("key") public key: string = "";
  @Input("backgroundImage") public backgroundImage: string = "";
  @Input("clickedImage") public clickedImage: string = "";
  @Input("max-objects")  public maxObjects!: number;
  @ViewChild("kh-manipulative-region") public regionElement!: ElementRef;
  public scorableData: ScorableData = { answer: {}, state: {} };
  public contentChild!: DynamicContentChild[]; 
  public regionsData: ScorableData[] = [];
  public box = { left: 0, top: 0, width: 0, height: 0, };

  public ngOnInit() {
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    this.box = { left, top, width, height };
  }

  public constructor(@Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService) {
    this.questionEventsService.hideInputOnlyForManip.next(true);
  }

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];
    this.contentChild = contentChildren;
    contentChildren.forEach((c, index) => {
      if (c.componentRef.instance instanceof KhManipulativeRegionComponent) {
        const curComponent = c.componentRef.instance;
        curComponent.clickedImage = curComponent.clickedImage ? curComponent.clickedImage : this.clickedImage;
        curComponent.value = this.scorableData;
        curComponent.index = index;
        if (this.scorableData) {
          curComponent.selected = this.scorableData.state[curComponent.key]
            ? true
            : false;
        }
        curComponent.clickCallback = this.onClickChangeValue.bind(this);
      } else if (c.componentRef.instance instanceof KhManipScorableIntInput) {
        const curComponent = c.componentRef.instance;
        curComponent.value = this.scorableData.state[curComponent.key] ?? "";
        curComponent.onChangeCallback = this.onChangeValue.bind(this);
      }
    });
  }

  public onClickChangeValue(val: any): void {
    this.setAnswerAndState(val, ManipulativeRegionType.MANIP_REGION);
    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  public onChangeValue(val: any): void {
    this.setAnswerAndState(val, ManipulativeRegionType.MANIP_INT_INPUT_REGION);
    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  public getScorableData(): ScorableData {
    const data: ScorableData = {
      answer: this.scorableData.answer,
      state: this.scorableData.state,
    };
    return data;
  }

  public setScorableData(scorableDatata: ScorableData): void {
    this.scorableData = scorableDatata;
  }

  private setAnswerAndState(data: ScorableData,manipulativeType: ManipulativeRegionType): void {
    const currentRegionAnswerKey = Object.keys(data.answer)[0];
    const currentRegionAnswerValue = Object.values(data.answer)[0];
    const currentRegionStateKey = Object.keys(data.state)[0];
    const currentRegionStateValue = Object.values(data.state)[0];

    if (manipulativeType === ManipulativeRegionType.MANIP_REGION) {// for region manipulative
      if (
        currentRegionStateValue === "active"){
          const currentRegionContent = this.contentChild.find(c => c.componentRef.instance.key === currentRegionStateKey)?.componentRef.instance;
        if(currentRegionContent.ignoreInScore?.toString() !== 'true')
        {
          this.scorableData.answer[currentRegionAnswerKey] = currentRegionAnswerKey;
        }
        else{
          delete this.scorableData.answer[currentRegionAnswerKey];
        }          
        this.scorableData.state[currentRegionStateKey] = currentRegionStateKey;
      }
      if (currentRegionStateValue !== "active") {
        delete this.scorableData.answer[currentRegionAnswerKey];
        delete this.scorableData.state[currentRegionStateKey];
      }
    } else if (manipulativeType === ManipulativeRegionType.MANIP_INT_INPUT_REGION) {// for Int Integer manipulative
      if (currentRegionAnswerValue?.length > 0) {
        this.scorableData.answer[currentRegionAnswerKey] = currentRegionAnswerValue;
        this.scorableData.state[currentRegionStateKey] = currentRegionStateValue;
      }
      else {
        delete this.scorableData.answer[currentRegionAnswerKey];
        delete this.scorableData.state[currentRegionStateKey];
      }

    }
  }
}
