import { WrappedBoolean } from 'app/core/kh-utils/wrapped-boolean';
import { WrappedString } from 'app/core/kh-utils/wrapped-string';
import { environment } from 'env/environment';
import { DateTime } from 'luxon';

export function stringWrapper(text: string | number): WrappedString {
  const value = typeof (text) === 'string' ? text : String(text);

  return { Value: value };
}

export function booleanWrapper(value: boolean): WrappedBoolean {
  return { Value: value };
}

export function getLottieFile(fileName: string): string {
  return `${environment.AppBaseHref}${environment.imageBasePath}/lottie/${fileName}.json`;
}

export function getAssetsPath(path: string): string {
  if (!path) {
    return '';
  }
  path = path.trim();
  if (path.startsWith('http')) {
    return path;
  }

  return `${ environment.imageBasePath }${ path.startsWith('/') ? '' : '/' }${ path }`;
}

export function getUrlBasePart(): string {
  const baseHref = environment.AppBaseHref.replace(RegExp('\/$'), '');

  return `${location.origin}${baseHref}`;
}

export function fromServerDate(dateString: string): Date {
  return DateTime.fromISO(dateString.split('T')[0]).toJSDate();
}

//TODO: changed 
export function toServerDate(localDate: Date): string {
  return DateTime.fromJSDate(localDate).toISODate() ?? DateTime.now.toString();
}
