import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  //TODO: changed 
  public handle(params: MissingTranslationHandlerParams): string {
    let interpolateParam =  params.interpolateParams
    ? params.translateService.parser.interpolate(params.key, params.interpolateParams)
    : params.key;
    return interpolateParam ?? '';
  }
}
