import { DefaultUrlSerializer, PRIMARY_OUTLET, UrlTree } from '@angular/router';

const EMPTY_PARAMETER_VALUE = '_EMPTY_PARAM_';
const EMPTY_PARAMETER_REPLACEMENT = '/' + EMPTY_PARAMETER_VALUE + '/';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  public parse(url: string): UrlTree {
    const urlParts = url.split('?');
    urlParts[0] = urlParts[0].replace(/\/\//g, EMPTY_PARAMETER_REPLACEMENT);
    const doubleSlashEscapedUrl = urlParts.join('?');
    let tree = super.parse(doubleSlashEscapedUrl);
    tree.root.children[PRIMARY_OUTLET]?.segments
      .filter(s => !s.parameters || Object.keys(s.parameters).length === 0)
      .forEach(s => s.path = s.path === EMPTY_PARAMETER_VALUE ? '' : s.path.toLowerCase());
    return tree;
  }
}
