import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UploadFileInfoModel } from 'app/shared/modals/text-editor-file-upload-modal/upload-file-info.model';
import { TEMPLATE_PREFIX } from 'env/locale-config';

@Component({
  selector: 'kh-text-editor-file-upload-modal',
  templateUrl: TEMPLATE_PREFIX + 'text-editor-file-upload-modal.component.html',
  styleUrls: ['./text-editor-file-upload-modal.component.less']
})
export class TextEditorFileUploadModalComponent {
  public form = this.initForm();
  @Output() public closed = new EventEmitter<UploadFileInfoModel | null>();
  @Output() public chooseSource = new EventEmitter<void>();

  constructor(private readonly formBuilder: FormBuilder) {}

  @Input()
  public set sourceImage(image: UploadFileInfoModel) {
    if (image) {
      this.form.setValue(image);
    }
  }

  public showChooseSourceModal(): void {
    this.chooseSource.emit();
  }

  public submitForm({value, valid}: FormGroup): void {
    this.closed.emit(value.src ? value : null);
  }

  public closeModal(): void {
    this.closed.emit();
  }

  private initForm(): FormGroup {
    return this.formBuilder.group({
      width: '',
      height: '',
      alt: '',
      src: '',
      constrain: true
    });
  }
}