<ng-template #uploadFileModal tabindex="0" role="dialog" aria-labelledby="dialog-child-name">
    <kh-text-editor-file-upload-modal
        [sourceImage]="sourceImage"
        (closed)="formClosed($event)"
        (chooseSource)="formOpened()"></kh-text-editor-file-upload-modal>
</ng-template>

<ng-template #chooseSourceModal tabindex="0" role="dialog" aria-labelledby="dialog-child-name">
  <kh-upload-files-listing-modal [imagePath]="imagePath" (linkSet)="linkUpdated($event)"></kh-upload-files-listing-modal>
</ng-template>
