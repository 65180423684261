import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {
  private theme$ = new Subject<string>();

  public themeChanged(): Observable<string> {
    return this.theme$.pipe(map((theme) => `${theme}.css`));
  }

  public changeTheme(theme: string): void {
    this.theme$.next(theme);
  }
}
