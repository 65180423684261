import { environment } from 'env/environment';
import { LANGUAGE } from 'app/core/internationalization/language';

export function buildLocaleUrl(localeId: string): string {
  let supportedLanguages = [ getLangPart(LANGUAGE.FRENCH_CANADA), getLangPart(LANGUAGE.SPANISH_MEXICO) ];
  let localeLanguage = getLangPart(localeId);

  return window.location.href.replace(new RegExp(
    environment.AngularJsBaseUrl + '(?:/(?:es|fr))?'),
    environment.AngularJsBaseUrl + (supportedLanguages.includes(localeLanguage) ? '/' + localeLanguage  : ''));
}

export function getLangPart(localeId: string) {
  return localeId.substring(0, localeId.indexOf('-'))
}

export function buildLocatedFileUrl(url: string, localeId: string): string {
  const lang = getLangPart(localeId);
  return lang
    ? url.replace(/\.(\w+)$/, `_${ lang }.$1`)
    : url;
}
