import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { ScorableData } from "../kh-manip-scorable/IScorable";
@Component({
  selector: "kh-manipulative-region",
  templateUrl: "kh-manip-scorable-region.component.html",
  styleUrls: ["kh-manip-scorable-region.component.less"],
})
export class KhManipulativeRegionComponent implements AfterViewChecked {
  public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @Input("key") public key: string = "";
  @Input("index") public index!: number;
  @Input("value") public value!: ScorableData;
  @Input("shape") public shape: string = "rectagle";
  @Input("background") public background: string = "";
  @Input("hasOutline") public hasOutline: boolean = false;
  @Input("frame") public frame = "0 0 0 0";
  @Input("clickedImage") public clickedImage?: string;
  @Input("ignoreInScore") public ignoreInScore = false;
  @Input("defaultValue") public defaultValue?: string;
  @Input("selected") selected: boolean = false; 
  @ViewChild("region", { static: true })
  public regionElement!: ElementRef<HTMLInputElement>;

  public clickCallback: undefined | ((data: ScorableData) => void); // callback
  public box = { left: 0, top: 0, width: 0, height: 0 };
  public borderRadius = "";
  public border: string = "";

  public ngOnInit() {
    this.setBackground();
    this.borderRadius = this.shape?.toLocaleLowerCase() === "oval" ? "50%" : "";
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    this.box = { left, top, width, height };
    this.border = this.hasOutline ? "1px solid black" : "none";
  }

  private setBackground() {
    if (this.selected) {
      this.regionElement.nativeElement.style.backgroundImage = `url(${this.clickedImage})`;
    } else {
      this.regionElement.nativeElement.style.backgroundImage = "";
    }
  }

  ngAfterViewChecked(): void {
    this.setBackground();
  }

  public onChangeValue(val: string): void {
    this.selected = !this.selected;
    this.setBackground();

    if (this.clickCallback) {
      this.clickCallback(
        this.getRegionScorableData()
      );
    }
  }

  public getRegionScorableData() {
    const data: ScorableData = {
      answer: { [this.key]: this.key },
      state: { [this.key]: this.selected ? "active" : "inactive" },
    }

    return data;
  }
}
