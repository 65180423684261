import { Component } from '@angular/core';
import { TEMPLATE_PREFIX } from 'env/locale-config';

@Component({
  selector: 'kh-loading-status',
  templateUrl: TEMPLATE_PREFIX + 'loading-status.component.html',
  styleUrls: ['./loading-status.component.less']
})
export class LoadingStatusComponent {
}
