export const SETTINGS_ROUTE = {
  name: 'settings',
  route(): string[] {
    return ['/', this.name];
  },
  DEFAULT: '',

  MANAGE_EMAIL_PREFERENCES: {
    name: 'manageemailpreferences',
    route(): string[] {
      return [...SETTINGS_ROUTE.route(), this.name];
    }
  }
};
