import * as _ from 'lodash-es';
import { Injectable } from '@angular/core';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  public constructor(
    private readonly authenticationService: AuthenticationService) { }

  public isInRole(role: string): boolean {
    const claims = this.authenticationService.getUserClaims();

    return claims && claims.roles && _.indexOf(claims.roles, role) >= 0;
  }

  public hasAnyRole(roles: string[]): boolean {
    const claims = this.authenticationService.getUserClaims();

    return claims && claims.roles && _.intersection(claims.roles, roles).length > 0;
  }
}
