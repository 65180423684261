/* eslint-disable max-len */
export type OverlayNumberInputConfig = {
  width: number;
  height: number;
  x: number;
  y: number;
  className?: string;
  style?: string;
  ignoreDecimalsInLength? : boolean;
  minNumber?: number;
  maxLength?: number;
  type?: 'number' | 'text';
  defaultValue?: number;
  positionStyle?: string;
}

const defaultConfig: OverlayNumberInputConfig = {
  width: 100,
  height: 50,
  x: 0,
  y: 0,
  className: '',
  style: '',
  ignoreDecimalsInLength: false,
  type: 'text',
  positionStyle: 'absolute'
}

export class OverlayNumberInput {
  private parent: HTMLElement;
  private element: HTMLInputElement;
  private config: OverlayNumberInputConfig;
  private value: number;
  public onChange?: (value: number) => void;

  public constructor(config: OverlayNumberInputConfig, parent: HTMLElement) {
      this.parent = parent;
      this.config = {...defaultConfig, ...config};

      this.makeInput();
      this.setupEvents();
  }

  private makeInput(): void {
    this.element = document.createElement('input');

    if (this.config.type === 'number') {
      this.element.inputMode = 'numeric';
    }

    let computedStyle = `position: ${this.config.positionStyle}; width: ${this.config.width}px; height: ${this.config.height}px; left: ${this.config.x}px; top: ${this.config.y}px; pointer-events: all;`;

    if (this.config.style) {
      computedStyle = `${computedStyle} ${this.config.style}`;
    }

    this.element.setAttribute('style', computedStyle);

    if (this.config.className) {
      this.element.setAttribute('class', this.config.className);
    }

    this.parent.appendChild(this.element);

    // remember check for null and undefined only because '0' in JS also counts as a falsy condition
    if (this.config.defaultValue !== undefined && this.config.defaultValue !== null) {
      this.value = this.config.defaultValue;

      if (this.element) {
        this.element.value = this.value.toString();
      }
    }
  }

  private setupEvents(): void {
    this.element.addEventListener('input', evt => {
      const newValue = Number((evt.target as HTMLInputElement).value);

      // do not allow invalid numbers
      if (Number.isNaN(newValue)) {
          this.element.value = (this.value || '').toString();
          return;
      }

      if (this.config.maxLength && this.getValueStringLength(newValue) > this.config.maxLength) {
          (evt.target as HTMLInputElement).value = this.value.toString();
      } else {
          this.value = newValue;

          if (this.onChange) {
              this.onChange(this.value);
          }
      }
  });
  }

  private getValueStringLength(num: number): number {
    let stringNumber = num.toString();
    // if we're ingoring decimals, count the number of numbers in the string
    if (this.config.ignoreDecimalsInLength) {
        stringNumber = stringNumber.replace('.', '');
    }

    return stringNumber.length;
  }

  public getElement(): HTMLInputElement {
    return this.element;
  }

  public getValue(): number {
    return this.value;
  }

  public setValue(value: number): void {
    this.value = value;
    this.element.value = value.toString();
  }
}