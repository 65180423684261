import { AuthenticationDataService } from 'app/core/authentication';
import { AuthService } from 'ngx-auth';
/*import { ChatService } from 'app/core/chat/chat.service';*/
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { IToken } from '../models/token';
import { IUserClaims } from '../models/user-claims';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { TranslationService } from 'app/core/internationalization/translation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements AuthService {

  private interruptedUrl = '';
  private userLoggedOut$ = new Subject<void>();
  private userLoggedIn$ = new ReplaySubject<void>();

  public constructor(
    private readonly authenticationDataService: AuthenticationDataService,
    /*private readonly chatService: ChatService,*/
    private readonly translationService: TranslationService,
    @Optional() @SkipSelf() parent?: AuthenticationService) {
    if (parent) {
      throw Error('Trying to create second instance of AuthenticationService.');
    }
  }

  public isAuthorized(): Observable<boolean> {
    return this.authenticationDataService.isAuthorized();
  }

  public getAccessToken(): Observable<string> {
    return of(this.authenticationDataService.getAccessToken() || '');
  }

  public refreshToken(): Observable<IToken> {
    throw new Error('not Implemented');
  }

  public getAuthMethod(): string | null | undefined {
    return this.authenticationDataService.getAuthData()?.AuthMethod;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    // as no refresh functionality available
    return false;
  }

  public getInterruptedUrl(): string {
    return this.interruptedUrl;
  }

  public setInterruptedUrl(url: string): void {
    this.interruptedUrl = url;
  }

  public setTokens(tokens: IToken): void {
    this.authenticationDataService
      .setAccessToken(tokens.access_token);

    this.userLoggedIn$.next();
  }

  public logOut(): void {
    this.authenticationDataService.clearUserInfo();
    /*this.chatService.logOut();*/
    this.userLoggedOut$.next();
  }

  public isUserAuthorized(): boolean {
    return this.authenticationDataService.isExistAccessToken();
  }

  public setUserClaims(userClaims: IUserClaims): void {
    this.authenticationDataService.setUserClaims(userClaims);
    const lang = this.translationService.getCurrentLanguage();
    this.translationService.switchLanguage(userClaims?.localeId ?? '');

    const truncatedLang = lang.substring(0, 2);
    const truncatedCurrentLang = this.translationService.getCurrentLanguage().substring(0, 2);

    if (truncatedLang.toLowerCase() !== truncatedCurrentLang.toLowerCase()) {
      location.reload();
    }
  }

  public getUserClaims(): IUserClaims {
    return this.authenticationDataService.getUserClaims();
  }

  public userLoggedOut(): Observable<void> {
    return this.userLoggedOut$;
  }

  public userLoggedIn(): Observable<void> {
    return this.userLoggedIn$;
  }

  public roles(): string[] {
    if (!this.isUserAuthorized()) {
      return [];
    }

    return this.getUserClaims().roles ?? [];
  }

  public isInRole(role: string): boolean {
    return this.roles().includes(role);
  }

  public isTransient(): boolean {
    if (!this.isUserAuthorized()) {
      return false;
    }

    return this.getUserClaims().isTransient;
  }

  public userId(): string | null {
    if (!this.isUserAuthorized()) {
      return null;
    }

    return this.getUserClaims().userId;
  }
}
