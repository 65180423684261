import { Injectable } from '@angular/core';
import { ISchoolAuthData } from '../models/school-auth-data';
import { IUserClaims } from '../models/user-claims';
import { LANGUAGE } from 'app/core/internationalization/language';
import { LOCALE_CONFIG } from 'env/locale-config';
import { Observable, of } from 'rxjs';
import { TOKEN_STORAGE_KEYS } from '../constants/token-storage-keys';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationDataService {
  public isAuthorized(): Observable<boolean> {
    return of(this.isExistAccessToken());
  }

  public isExistAccessToken(): boolean {
    return !!this.getAccessToken();
  }

  public isTransient(): boolean | null {
    return !this.isAuthorized() ? null : !!this.getAuthData()?.IsTransient;
  }

  public getUserLocale(): string {
    return this.getAuthData()?.LocaleId ?? LOCALE_CONFIG.APP_LOCALE;
  }

  public setUserLocale(localeId: string): void {
    if (!localeId) {
      localeId = LANGUAGE.ENGLISH_CANADA;
    }

    const authData = this.getAuthData();
    if(!authData) {
      return;
    }

    this.setAuthData({...authData, LocaleId: localeId});
  }

  public getAuthData(): ISchoolAuthData | null {
    const authData = localStorage.getItem(TOKEN_STORAGE_KEYS.SCHOOL_AUTH_DATA);

    return authData ? JSON.parse(authData) as ISchoolAuthData : null;
  }

  public setAuthData(authData: ISchoolAuthData | null): void {
    if (authData !== null) {
      localStorage.setItem(TOKEN_STORAGE_KEYS.SCHOOL_AUTH_DATA, JSON.stringify(authData));
    } else {
      localStorage.removeItem(TOKEN_STORAGE_KEYS.SCHOOL_AUTH_DATA);
    }
  }

  public getAccessToken(): string | null {
    let token = localStorage.getItem(TOKEN_STORAGE_KEYS.ACCESS_TOKEN);
    if (!token) {
      const authData = this.getAuthData();
      if (authData) {
        token = authData.Token;
        if (token) {
          this.setAccessToken(token);
        }
      }
    }

    return token;
  }

  public setAccessToken(accessToken: string | null): AuthenticationDataService {
    let authData = this.getAuthData();
    if (accessToken !== null) {
      localStorage.setItem(TOKEN_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
      if (!authData) {
        authData = {} as ISchoolAuthData;
      }
      authData.Token = accessToken;
    } else {
      localStorage.removeItem(TOKEN_STORAGE_KEYS.ACCESS_TOKEN);
      if (authData) {
        authData.Token = null;
      }
    }
    this.setAuthData(authData);

    return this;
  }

  public clearUserInfo(): void {
    localStorage.removeItem(TOKEN_STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(TOKEN_STORAGE_KEYS.USER_CLAIMS);
    localStorage.removeItem(TOKEN_STORAGE_KEYS.SCHOOL_AUTH_DATA);
  }

  public setUserClaims(userClaims: IUserClaims | null): void {
    let authData = this.getAuthData();
    if (userClaims !== null) {
      localStorage.setItem(TOKEN_STORAGE_KEYS.USER_CLAIMS, JSON.stringify(userClaims));
      if (!authData) {
        authData = {} as ISchoolAuthData;
      }
      authData.UserId = userClaims.userId;
      authData.Username = userClaims.userName;
      authData.LocaleId = userClaims.localeId;
      authData.IsTransient = userClaims.isTransient;
      authData.Roles = userClaims.roles;
      authData.Expiry = userClaims.expiry;
      authData.AuthMethod = userClaims.authMethod;
    } else {
      if (authData) {
        if (authData.Token === null) {
          authData = null;
        } else {
          authData.UserId = null;
          authData.Username = null;
          authData.LocaleId = null;
          authData.IsTransient = null;
          authData.Roles = [];
          authData.Expiry = null;
          authData.AuthMethod = null;
        }
      }
      localStorage.removeItem(TOKEN_STORAGE_KEYS.USER_CLAIMS);
    }
    this.setAuthData(authData);
  }

  public getUserClaims(): IUserClaims {
    const claimItem = localStorage.getItem(TOKEN_STORAGE_KEYS.USER_CLAIMS);
    let userClaims = null;
    if (claimItem) {
      userClaims = JSON.parse(claimItem);
    } else {
      const authData = this.getAuthData();
      if (authData) {
        userClaims = {
          authMethod: authData.AuthMethod,
          expiry: authData.Expiry,
          isTransient: authData.IsTransient,
          localeId: authData.LocaleId,
          roles: authData.Roles,
          userId: authData.UserId,
          userName: authData.Username
        } as IUserClaims;
        this.setUserClaims(userClaims);
      }
    }

    return userClaims;
  }
}
