<div [ngClass]="{'video-container': !hasClass}">
    <kh-video-with-events
            [videotype]="videoType"
            [videoId]="videoId"
            [attemptid]="attemptId"
            [attemptitemorder]="attemptItemOrder"
    ></kh-video-with-events>
    <div class="videoVoteContainer" *ngIf="!hideRatings">
        Was this video helpful? &nbsp;&nbsp;
        <div class="videoVoteButtonsContainer">
            <a class="btn btn-xs" [ngClass]="ratingBtnClasses(10)" (click)="rateVideo(+10)">
                <fa-icon [icon]="['far', 'thumbs-up']"></fa-icon>
                Yes!</a>
            <a class="btn btn-xs" [ngClass]="ratingBtnClasses(-10)" (click)="rateVideo(-10)">
                <fa-icon [icon]="['far', 'thumbs-down']"></fa-icon>
                Nope</a>
        </div>
    </div>
</div>
