import { UploadFileInfoModel } from 'app/shared/modals/text-editor-file-upload-modal/upload-file-info.model';
import Quill from 'quill';

const blotPath = 'formats/image';
const BlockEmbed = Quill.import(blotPath);

class ImageBlot extends BlockEmbed {
  static create(data: UploadFileInfoModel) {
    const src = data.src ?? '';

    const node = super.create(src);
    node.setAttribute('src', data.src);
    node.setAttribute('width', data.width ?? '');
    node.setAttribute('height', data.height ?? '');
    node.setAttribute('alt', data.alt ?? '');

    return node;
  }

  static value(domNode: any): object {
    return {
      alt: domNode.getAttribute('alt') ?? '',
      src: domNode.getAttribute('src'),
      width: domNode.getAttribute('width') ?? '',
      height: domNode.getAttribute('height') ?? '',
    };
  }
}

ImageBlot.tagName = 'img';
ImageBlot.blotName = 'image';

export default ImageBlot;
