import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { IInputDialogData } from './input-dialog-data';

import { TEMPLATE_PREFIX } from 'env/locale-config';

@Component({
  selector: 'kh-input-dialog',
  styleUrls: ['./input-dialog.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'input-dialog.component.html'
})
export class InputDialogComponent implements IInputDialogData {

  public title = '';
  public body = '';
  public firstButtonText = '';
  public secondButtonText = '';
  public inputControl: AbstractControl;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public firstButtonHandler(value: string): void {
    // do nothing. That is a placeholder.
  }

  public secondButtonHandler(): void {
    // do nothing. That is a placeholder.
  }

  public constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly builder: FormBuilder) {

    this.inputControl = builder.control('', Validators.required);
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public onFirstButtonClick(): void {
    this.inputControl.markAsTouched();
    if (this.inputControl.valid){
      this.close();
      this.firstButtonHandler(this.inputControl.value);
    }
  }

  public onSecondButtonClick(): void {
    this.close();
    this.secondButtonHandler();
  }
}
