/* eslint-disable sort-keys-fix/sort-keys-fix */

import { EDITOR_ROUTE } from 'app/routing-constants/editor-routing.constant';
import { ADMIN_ROUTE } from 'app/routing-constants/admin-routing.constant';
import { SETTINGS_ROUTE } from 'app/settings/settings-routing.constant';

// NOTE: name should be lowercase as lowercase serializer is used.
export const APP_ROUTE = {
  ADMIN: ADMIN_ROUTE,
  DEFAULT: '',
  UNAUTHORIZED: 'unauthorized',
  EDITOR: EDITOR_ROUTE,
  EDITOR_DEFAULT: {
    name: 'editor',
    route(): string[] {
      return ['/' + this.name];
    }
  },
  SETTINGS: SETTINGS_ROUTE,
  LOGIN: {
    name: 'login',
    route(): string[] {
      return ['/' + this.name];
    }
  }
};
