export enum UserRole {
  admin = 'Administrator',
  contentSearch = 'ContentSearch',
  contentViewer = 'Content Viewer',
  csAdministrator = 'CS Administrator',
  curriculumReviewer = 'CurriculumReviewer',
  editor = 'Editor',
  editorLite = 'Editor Lite',
  firewallAdministrator = 'FirewallAdministrator',
  humanResources = 'Human Resources',
  licenseManager = 'License Manager',
  mathLead = 'Math Lead',
  mediaAdmin = 'Media Administrator',
  mediaRepresentative = 'Media Representative',
  parent = 'Parent',
  premiumSchoolAdministrator = 'PremiumSchoolAdministrator',
  principal = 'Principal',
  principalAdministrator = 'Principal Administrator',
  purchaser = 'Purchaser',
  questionCloner = 'QuestionCloner',
  reviewer = 'Reviewer',
  salesRepresentative = 'Sales Representative',
  schoolAdminApprover = 'SchoolAdminApprover',
  slsDirector = 'SLS Director',
  slsLead = 'SLS Lead',
  slsMathCoach = 'SLS Math Coach',
  slsMathConsultant = 'SLS Math Consultant',
  slsMathLead = 'SLS Math Lead',
  slsOther = 'SLS Other',
  slsPrincipal = 'SLS Principal',
  slsSuperintendent = 'SLS Superintendent',
  student = 'Student',
  superintendent = 'Superintendent',
  teacher = 'Teacher',
  translator = 'Translator',
  vicePrincipal = 'Vice-Principal',
  viewOnlyParent = 'ViewOnlyParent',
  teacherReports = 'Teacher Reports',
  studentReports = 'Student Reports',
  parentReports = 'Parent Reports',
  huddleAdmin = 'Huddle Admin',
  homeAdministrator = 'Home Administrator',
  regionalLeader = 'Regional Leader'
}
