import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { VideoDataService } from 'app/shared/components/video/video-data.service';

import { TEMPLATE_PREFIX } from 'env/locale-config';

@Component({
  selector: 'kh-video',
  templateUrl: TEMPLATE_PREFIX + 'video.component.html',
  styleUrls: ['./video.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent {
  public hasClass = !!this.elementRef.nativeElement.classList.length;

  @Input() public hideRatings: boolean | number = 0;
  @Input() public rating?: number;
  // for player
  @Input() public videoId = '';
  @Input() public attemptId = '';
  @Input() public questionAttemptId = '';
  @Input() public attemptItemOrder = 0;
  @Input() public trainingContentId = '';
  @Input() public videoType = '';

  constructor(private readonly elementRef: ElementRef,
              private readonly videoDataService: VideoDataService) { }

  public rateVideo(rating: number): void {
    this.rating = this.rating === rating ? 0 : rating;

    this.videoDataService.rateVideo(this.videoId, this.rating, this.attemptId, this.questionAttemptId).subscribe();
  }

  public ratingBtnClasses(rating: number): Record<string, boolean> {
    return { 'btn-default': this.rating !== rating, 'btn-primary': this.rating === rating };
  }
}
