import { AuthModule } from 'ngx-auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';

import { AppRoutingModule } from '../app-routing.module';
import {
  AuthenticationDataService,
  AuthenticationService,
  AuthGuard,
  PermissionsService,
  PublicRedirectGuard
} from './authentication';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { EnvironmentService } from './app-constants/environment.service';
import { Logger } from './logger/services/logger.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { UrlService } from './http/url.service';

import { AppNavigationService, ExternalLinkGuard, RouterService } from './navigation';
import { DatabaseProviderService } from './storage/providers/database-provider.service';
import { FirebaseProviderService } from './storage/providers/firebase-provider.service';

import { ToastrModule } from 'ngx-toastr';
import { InputDialogComponent } from './modals/input-dialog/input-dialog.component';
import { InputComponent } from './modals/input/input.component';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    EmptyLayoutComponent,
    ConfirmDialogComponent,
    InputDialogComponent,
    InputComponent
  ],
  imports: [
    AuthModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule,
    ModalModule.forRoot(),
    ReactiveFormsModule
  ],
  providers: [
    AuthenticationService,
    AuthenticationDataService,
    AuthGuard,
    ExternalLinkGuard,
    Logger,
    RouterService,
    AppNavigationService,
    UrlService,
    PublicRedirectGuard,
    PermissionsService,
    BsModalService,
    EnvironmentService,
    { provide: DatabaseProviderService, useClass: FirebaseProviderService }
  ]
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
