import { Component, Input } from "@angular/core";
import { ScorableData } from "../kh-manip-scorable/IScorable";
import { ControlType } from "../../../enums/control-type.enum";

@Component({
  selector: "kh-manipulative-forward",
  templateUrl: "kh-manip-forward.component.html",
  styleUrls: ["kh-manip-forward.component.less"],
})
export class KhManipulativeForwardComponent {
  @Input("id") public id: string = "";
  @Input("image") public image: string = "";
  @Input("frame") public frame = "0 0 100 100";
  public clickCallback: undefined | ((data: ScorableData) => void);
  public box = { left: 0, top: 0, width: 0, height: 0, };

  public ngOnInit() {
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    this.box = { left, top, width, height };
  }

  public onChangeValue(): void {
    if (this.clickCallback) {
      this.clickCallback(
        this.getRegionScorableData()
      );
    }
  }

  public getRegionScorableData() {
    const data: ScorableData = {
      answer: { [this.id]: ControlType.FORWARD },
      state: { [this.id]: ControlType.FORWARD },
    }

    return data;
  }
}
