import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NativeAppService {
  public platform: string;

  constructor() {
    this.platform = this.getPlatform();
  }

  private getPlatform(): string {
    const platform = localStorage.getItem("KH.Platform");

    if (platform !== 'ios' && platform !== 'android') {
      return "Web"
    }

    return platform;
  }

  public notify(action: string, payload?: object): void {
    if (this.platform === 'ios') {
      // @ts-ignore
      window.webkit.messageHandlers["KH.NativeCall"].postMessage({ Action: action, Payload: payload });
      return;
    }

    if (this.platform === 'android') {
      // @ts-ignore
      Android.postAnswerResult(JSON.stringify({ Action: action, Payload: payload }));
      return;
    }
  }

  public isNativeApp(): boolean {
    return this.platform === 'ios' || this.platform  === 'android';
  }
}
