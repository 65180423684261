/* eslint-disable sort-keys-fix/sort-keys-fix */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTE } from './app-routing.constant';
import { EDITOR_ROUTE } from './routing-constants/editor-routing.constant';

// defaults to prod setup.
let DEFAULT_ROUTE_OBJ: any = {
  path: APP_ROUTE.DEFAULT,
  children: [
    {
      path: APP_ROUTE.DEFAULT,
      loadChildren: () =>
        import('./access/access.module')
        .then(m => m.AccessModule)
    },
    {
      path: APP_ROUTE.UNAUTHORIZED,
      redirect: 'saml',
      loadChildren: () =>
        import('./access/access.module')
          .then(m => m.AccessModule),
      data: { unauthorized: true },
    }
  ],

}

const routes: Routes = [
  DEFAULT_ROUTE_OBJ,
  {
    path: APP_ROUTE.EDITOR.name,
    children: [
      {
        path: EDITOR_ROUTE.DEFAULT,
        loadChildren: () => import('./editor/editor.module').then(m => m.EditorModule)
      }
    ]
  },
  {
    path: APP_ROUTE.SETTINGS.name,
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled', enableTracing: true, useHash: false 
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
