import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModalService } from 'app/core/modals/services/modal.service';
import { UploadFileInfoModel } from 'app/shared/modals/text-editor-file-upload-modal/upload-file-info.model';
import { TEMPLATE_PREFIX } from 'env/locale-config';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'kh-upload-file-modals-holder',
  templateUrl: TEMPLATE_PREFIX + 'upload-file-modals-holder.component.html',
  styleUrls: ['./upload-file-modals-holder.component.less']
})
export class UploadFileModalsHolderComponent implements OnInit {
  @ViewChild('uploadFileModal', {static: false}) uploadFileModal: TemplateRef<any> | undefined;
  @ViewChild('chooseSourceModal', {static: false}) chooseSourceModal: TemplateRef<any> | undefined;
  uploadFileModalRef?: BsModalRef;
  chooseSourceModalRef?: BsModalRef;
  @Input() public imagePath = '';

  @Output() public hideModals = new EventEmitter<UploadFileInfoModel | null>();

  private image: UploadFileInfoModel = this.getDefModel();

  constructor(private modalService: ModalService) {

  }
  public get sourceImage(): UploadFileInfoModel {
    return this.image;
  }

  @Input()
  public set sourceImage(image: UploadFileInfoModel | null) {
    this.image = image ?? this.getDefModel();
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.uploadFileModalRef = this.modalService.displayModal(this.uploadFileModal as TemplateRef<any>, {}, "sm-plus", "static", 'static');
    });
  }

  public linkUpdated(link: string): void {
    this.image = {...this.image, src: link};
    this.chooseSourceModalRef?.hide();
  }

  public formClosed(value: UploadFileInfoModel | null): void {
    this.uploadFileModalRef?.hide();
    this.hideModals.emit(value);
  }

  public formOpened() {
    this.chooseSourceModalRef = this.modalService.displayModal(this.chooseSourceModal as TemplateRef<any>, {}, "lg", "static", 'static');
  }

  private getDefModel(): UploadFileInfoModel {
    return {
      alt: '',
      height: '',
      width: '',
      src: '',
      constrain: true
    };
  }
}