/* eslint-disable indent */
import { Application, DisplayObject, IApplicationOptions } from 'pixi.js';
import { GameEntity } from './GameEntity';
import { Viewport } from 'pixi-viewport';
import { KHPoint } from './KHPoint';

export class Game {
    // public static instance: Game;

    public camera: Viewport;
    public app: Application;
    public alive = true;
    private entitites: GameEntity[] = [];
    private lastUpdateTime = 0;
    private parentDiv: HTMLElement
    private width: number;
    private height: number;
    private resizeListener: () => void;
    public static id = 0;
    private myId: number;
    

    public constructor(options: Partial<IApplicationOptions>, parentDiv: HTMLElement) {
        // Game.instance = this; // not great but I want a way to pass in the options. I'll figure this out

        this.myId = Game.id++;
        this.parentDiv = parentDiv;
        options.resizeTo = window;
        this.app = new Application(options);

        this.width = options.width || 720;
        this.height = options.height || 1280;

        this.camera = new Viewport({
            screenWidth: options.width,
            screenHeight: options.height,
            worldWidth: this.width* 10,
            worldHeight: this.height * 10,
            events: this.app.renderer.events
        });

        this.app.stage.addChild(this.camera as DisplayObject);

        this.resizeListener = () => this.resize();
        window.addEventListener('resize', this.resizeListener);

        parentDiv.appendChild(this.app.view as unknown as Node);

        this.start();
    }

    private start() {
        this.lastUpdateTime = performance.now();

        // start update loop
        this.update();

        this.resize();
    }

    private resize(): void {
        const width = this.parentDiv.clientWidth;
        const scaleFactor = width / this.width;

        this.app.stage.scale.set(scaleFactor);
        this.app.renderer.resize(width, width * 0.5625); // maintain 16:9 aspect ratio
        this.camera.resize(width, width * 0.5625);
    }

    public getScale(): number {
        return this.app.stage.scale.x;
    }

    public update() {
        const currentTime = performance.now();
        const deltaTime = (currentTime - this.lastUpdateTime) * 0.001; // Convert to seconds. Multiplication is faster than division.
        this.lastUpdateTime = currentTime;

        this.entitites.forEach(entity => {
            entity.components.forEach(component => {
                component.update(deltaTime);
            });

            entity.update(deltaTime);
        });

        if (this.alive) {
            requestAnimationFrame(() => this.update());
        }
        
    }

    public addEntity(entity: GameEntity, ignoreCamera = false) {
        entity.gameInstance = this;
        this.entitites.push(entity);

        if (ignoreCamera) {
            this.app.stage.addChild(entity as DisplayObject);
        } else {
            this.camera.addChild(entity as DisplayObject);
        }

        entity.start();
    }

    public destroy() {
        this.alive = false;

        this.entitites.forEach(entity => {
            entity.destroy();
        });

        this.entitites = [];
        this.camera.destroy();
        this.app.view.parentNode?.removeChild(this.app.view);
        this.app.destroy();

        window.removeEventListener('resize', this.resizeListener);
    }

    public pointToGlobal(point: KHPoint): KHPoint {
        const inverseScale = 1 / this.getScale();
        return {x: point.x * inverseScale, y: point.y * inverseScale};
    }

    public getGlobalPosition(obj: DisplayObject): KHPoint {
        const point = obj.getGlobalPosition();
        return this.pointToGlobal({x: point.x, y: point.y});
    }
}
