import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from 'app/core/modals/services/modal.service';
import { UploadSourceType } from 'app/shared/enums/upload-source-type.enum';
import { IUploadSourceModel } from 'app/shared/models/upload-source.model';
import { NativeAppService } from 'app/shared/services/native-app.service';
import { WebRtcInfoService } from 'app/shared/services/web-rtc-info.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { tap } from 'rxjs/operators';
import { SelectUploadSourceComponent } from './../../modals/select-upload-source/select-upload-source.component';

import { TEMPLATE_PREFIX } from 'env/locale-config';

@UntilDestroy()
@Component({
  selector: 'kh-upload-image',
  styleUrls: ['./upload-image.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'upload-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WebRtcInfoService]
})
export class UploadImageComponent implements OnInit {
  @Output() public fileUploaded = new EventEmitter<FileList | Blob[]>();

  @ViewChild('cameraView') public cameraView!: ElementRef;
  @ViewChild('photoCanvas') public photoCanvas!: ElementRef;

  public isWebCamActive: boolean = false;
  public isWebCamAvailable: boolean = false;
  public isPreviewDisplayed: boolean = false;

  //TODO: use @types/w3c-image-capture
  public captureDevice: any;
  public imageBlob!: Blob;

  constructor(private readonly nativeAppService: NativeAppService,
              private readonly modalService: ModalService,
              private readonly webRtcInfoService: WebRtcInfoService,
              private readonly deviceDetectorService: DeviceDetectorService,
              private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    if (!this.deviceDetectorService.isMobile() && this.webRtcInfoService.isAvailable() && !this.nativeAppService.isNativeApp()) { // Check for device availability, choose behaviour depending on what we get
        this.webRtcInfoService.getDeviceInfo()
        .pipe(
          tap(result => {
            this.isWebCamAvailable = result.hasVideo;
            this.changeDetectorRef.markForCheck();
          }), untilDestroyed(this)
        )
        .subscribe();
    }
  }

  public selectImageSource(): void {
    const modal = this.modalService.displayModal<SelectUploadSourceComponent>(SelectUploadSourceComponent, undefined, 'sm', true);

    modal.content!.closed
    .pipe(
      tap((result: IUploadSourceModel) => this.uploadSourceSelected(result)), untilDestroyed(this)
    )
    .subscribe();
  }

  public selectFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    this.uploadFile(files);
  }

  public cancelSnapshot(): void {
    const srcObject = this.cameraView.nativeElement.srcObject as MediaStream;

    if (srcObject != null) {
      srcObject
      .getVideoTracks()
      .forEach(track => track.stop());
    }

    this.isWebCamActive = false;
  }

  public takeSnapshot(): void {
    this.captureDevice.takePhoto()
    .then((blob: Blob) => {
      this.photoCanvas.nativeElement.src = window.URL.createObjectURL(blob);
      this.imageBlob = blob;
    })
    .catch(console.log);

    this.isPreviewDisplayed = true;
  }

  public uploadSnapshot(): void {
    this.uploadFile([this.imageBlob]);
    this.cancelSnapshot();
  }

  public retrySnapshot(): void {
    this.isPreviewDisplayed = false;
  }

  private uploadSourceSelected(result: IUploadSourceModel): void {
    switch (result.source) {
      case UploadSourceType.WebCam:
        this.useWebCam();
        break;
      case UploadSourceType.File:
        this.uploadFile(result.files);
        break;
    }
  }

  private useWebCam(): void {
    this.isWebCamActive = true;
    this.isPreviewDisplayed = false;
    this.changeDetectorRef.detectChanges();

    navigator.mediaDevices.getUserMedia({video: true})
    .then(mediaStream => {
      let videoDevice = mediaStream.getVideoTracks()[0];

      //TODO: use @types/w3c-image-capture
      // @ts-ignore
      this.captureDevice = new ImageCapture(videoDevice);

      this.cameraView.nativeElement.srcObject = mediaStream;
      this.cameraView.nativeElement.play();
    })
    .catch(error => this.handleWebcamConnectError(error));
  }

  private handleWebcamConnectError(error: any): any {
    const knownErrors: string[] = ['Could not start video source','Starting videoinput failed']
    if(error instanceof Error){
      const knownError: string | undefined = knownErrors.find(message => { return message.includes(error.message)})
      if(knownError){
        window.alert('Could not connect to webcam. Another program might be using it.')
      } else {
        console.error(error)
      }
    }
  }

  private uploadFile(files: FileList | Blob[] | null): void {
    if (!files) {
      return;
    }

    this.fileUploaded.emit(files);
  }
}
