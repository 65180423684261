import { ErrorHandler, Injectable } from '@angular/core';

import { Logger } from '../logger/services/logger.service';
import { RouterService } from '../navigation';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService implements ErrorHandler {

  public constructor(
    private readonly logger: Logger,
    private readonly router: RouterService) {

  }

  private createCircularReplacer(): (key: string, value: any) => any {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public handleError(error: any): void {
    let message: string;
    switch (typeof error) {
      case 'string':
        message = error as string;
        break;
      case 'object':
        message = JSON.stringify(error, this.createCircularReplacer(), 2);
        break;
      default:
        message = error.toString();
        break;
    }
    this.logger.error('unhandled exception', message, this.router.getUrl(), error.stack);
  }
}
