export const EDITOR_ROUTE = {
  name: 'editor',
  route(): string[] {
    return ['/', this.name];
  },
  DEFAULT: '',
  CONTENT_VIEW: {
    name: 'contentview',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), this.name];
    }
  },
  FLAGGED_QUESTIONS: {
    name: 'FlaggedQuestions',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), this.name];
    }
  },
  MAIN : {
    name: 'Main',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), this.name];
    }
  },
  COURSES: {
    name: 'Courses',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), this.name];
    }
  },
  LOGICAL_ERRORS: {
    name: 'LogicalErrors',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), this.name];
    }
  },
  BRANCH: {
    name: 'Branch/:domainid',
    route(domainid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'Branch', domainid];
    }
  },
  COURSE_FEATURED_CATEGORIES: {
    name: 'course/:courseid/featuredcategories',
    route(courseid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'course', courseid, 'featuredcategories'];
    }
  },
  FEATURED_CATEGORY_UNITS: {
    name: 'course/:courseid/featuredcategories/:categoryid/activities',
    route(courseid: string, categoryid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'course', courseid, 'featuredcategories', categoryid, 'activities'];
    }
  },
  FEATURED_CATEGORY_ACTIVITY: {
    name: 'featuredactivity/:featuredcategoryid/:publicunitid',
    route(featuredcategoryid: string, publicunitid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'featuredactivity', featuredcategoryid, publicunitid];
    }
  },
  FEATURED_UNIT: {
    name: 'featuredcategory/:featuredcategoryid/:publicunitid',
    route(featuredcategoryid: string, publicunitid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'featuredcategory', featuredcategoryid, publicunitid];
    }
  },
  UNIT: {
    name: 'unit/:unitid',
    route(unitid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'unit', unitid];
    }
  },
  QUESTIONBUCKET: {
    name: 'questionbucket/:questionBucketid',
    route(questionBucketid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'questionbucket', questionBucketid];
    }
  }, 
  
  LEARNINGGOAL_TRANSLATOR_PREVIEW: {
    name: 'LearningGoal/:learningGoalId/Translator/:localeId/Preview',
    route(learningGoalId: string, localeId: string): string[] {
      return [...EDITOR_ROUTE.route(), 'LearningGoal', learningGoalId, 'Translator', localeId, 'Preview'];
    }
  },
  LEARNINGGOAL_TRANSLATOR: {
    name: 'LearningGoal/:learningGoalId/Translator/:localeId',
    route(learningGoalId: string, localeId: string): string[] {
      return [...EDITOR_ROUTE.route(), 'LearningGoal', learningGoalId, 'Translator', localeId ];
    }
  },
  COURSE_WITH_NO_PARAM: {
    name: 'course',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), 'course'];
    }
  },
  COURSE: {
    name: 'course/:courseid',
    route(courseid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'course', courseid];
    }
  },
  EXPECTATION: {
    name: 'expectation/:expectationid',
    route(expectationid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'expectation', expectationid];
    }
  },
  LEARNING_GOAL: {
    name: 'learninggoal/:goalid',
    route(goalid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'learninggoal', goalid];
    }
  },
  QUESTION_EDITOR: {
    name: 'questioneditor',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), this.name];
    }
  },
  CUSTOM_ACTIVITIES: {
    name: 'customactivities',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), 'customactivities'];
    }
  },
  TRANSLATOR: {
    name: 'Expectation/:expectationid/Translator',
    route(expectationid: string): string[] {
      return [...EDITOR_ROUTE.route(), 'Expectation', expectationid,'Translator'];
    }
  },
  LOGICALERRORS: {
    name: 'logicalerrors',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), this.name];
    }
  },
  LOGICALERROR: {
    name: 'logicalerror/:logicalerrorId',
    route(logicalerrorId: string): string[] {
      return [...EDITOR_ROUTE.route(), "logicalerror", logicalerrorId];
    }
  },
  CURRICULUMBANKSEARCH: {
    name: 'curriculumbanksearch',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), "curriculumbanksearch"];
    }
  },
  BADGE_EDITOR: {
    name: 'badgeeditor',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), this.name];
    }
  },
  PREVIEW_QUESTIONS: {
    name: 'previewquestions',
    route(): string[] {
      return [...EDITOR_ROUTE.route(), this.name];
    }
  },
  PUBLISH_LEARNINGGOAL: {
    name: 'publishlearninggoal/:courseid/:coursename',
    route(courseid: string, coursename: string): string[] {
      return [...EDITOR_ROUTE.route(), "publishlearninggoal", courseid, coursename];
    }
  }
}
