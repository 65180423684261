<div class="modal-heading modal-heading-1 d-flex align-items-center justify-content-between">
  <div class="label_name">Image Manager</div>
  <fa-icon class="white" [icon]="['fas', 'times']" (click)="closeModal()"></fa-icon>
</div>
<div class="modal-body">
  <form id="fileForm" [formGroup]="form" (ngSubmit)="submitForm(form)">
    <div class="form-row align-items-center">
      <label class="col-4 col-form-label">Source</label>
      <div class="col-8">
        <div class="d-flex align-items-center justify-content-stretch input-group">
          <input class="form-control" formControlName="src"/>
          <div class="input-group-append">
            <button type="button" (click)="showChooseSourceModal()" class="btn btn-outline-primary upload-btn">
              <fa-icon [icon]="['fas','folder']"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row align-items-center">
      <label class="col-4 col-form-label">Image description</label>
      <div class="col-8 d-flex justify-content-stretch">
        <input class="form-control" formControlName="alt"/>
      </div>
    </div>
    <div class="form-row align-items-center">
      <label class="col-4 col-form-label">Dimensions</label>
      <div class="col-8">
        <div class="form-row">
          <div class="col-6 d-flex align-items-center justify-content-between">
            <input class="form-control size-form" formControlName="width"/>
            x
            <input class="form-control size-form" formControlName="height"/>
          </div>
          <div class="col-6 text-nowrap d-flex align-items-center constrain-field">
            <input type="checkbox" class="form-control" formControlName="constrain"/>
            Constrain proportions
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer d-flex align-items-center justify-content-end">
  <button class="btn btn-primary" type="submit" form="fileForm">Ok</button>
  <button class="btn btn-default" type="button" (click)="closeModal()">Cancel</button>
</div>