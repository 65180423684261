import moment from 'moment';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthenticationService, PermissionsService } from '../';
import { DefaultPageRouteService } from '../../navigation';

// https://github.com/jvandemo/generator-angular2-library/issues/221#issuecomment-355945207

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  public constructor(
    private authService: AuthenticationService,
    private permissionsService: PermissionsService,
    private router: DefaultPageRouteService,
    private readonly defaultRouteService: DefaultPageRouteService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthorized()
      .pipe(
        map((isAuthorized: boolean) => {
          if (!isAuthorized) {
            this.authService.setInterruptedUrl(state.url);
            if (state.url !== this.defaultRouteService.getCurrentFallbackPage()) {
              this.router.navigateToPublicFallback();
            }

            return false;
          }

          return true;
        }),
        map((authorized: boolean): boolean => {
          let result = authorized;
          if (result && this.isExpiredAccessToken()) {
            this.authService.setInterruptedUrl(state.url);
            this.authService.logOut();
            this.router.navigateToPublicFallback();
            // or return this.refreshToken() when implemented
            result = false;
          }

          return result;
        }),
        map((authorized: boolean): boolean => {
          let result = authorized;
          if (result && route.data && route.data.allowedRoles && route.data.allowedRoles.length > 0) {
            result = this.permissionsService.hasAnyRole(route.data.allowedRoles);
            if (!result) {
              this.authService.setInterruptedUrl('');
              this.authService.logOut();
              this.router.navigateToPublicFallback();
            }
          }

          return result;
        })
      );
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  private isExpiredAccessToken(): boolean {
    const accessTokenExpiration = this.getAccessTokenExpirationUtc();

    return moment()
      .utc()
      .isSameOrAfter(accessTokenExpiration);
  }

  private getAccessTokenExpirationUtc(): moment.Moment {
    return this.authService.getUserClaims().expiry;
  }
}
