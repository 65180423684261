import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { PuzzleBaseComponent } from '../../puzzle/puzzle.component';

/**
 * This component is a loader for PIXI based puzzles. You're probably wondering why it exists and what it does.
 * It is, effectively, a dynamic component loader that will load a PIXI based puzzle based on the first child tag.
 * This is necessary because of how Angular handles imports. If you try to put a PIXI based puzzle in a component that's
 * exists in the question module then it will tree-shake out to get bundled into the main JS file. This increases load times.
 *
 * We should probably eventually delete the question-text.component.ts file and just use the pattern in this class for dynamic component
 * injection. It will reduce our bundle size and then we can also uninstallthe  ngx-dynamic-hooks which would let me sleep better at night.
 */
@Component({
  selector: 'kh-puzzle',
  template: '<div #container><ng-content></ng-content></div>'
})
export class PixiPuzzleLoader implements AfterViewInit{
  @ViewChild('container', { read: ViewContainerRef }) public container!: ViewContainerRef;
  @Input() public submitFreeformAnswer: any;

  // put new PIXI based puzzles in here.
  // Unfortunately due to how import syntax works you need to define a new function for each one
  private puzzleMap: Map<string, () => Promise<ComponentRef<PuzzleBaseComponent>>> = new Map<string, () => Promise<ComponentRef<PuzzleBaseComponent>> >([
    ['kh-puzzle-farm', this.loadFarmPuzzle.bind(this)],
    ['kh-puzzle-ocean-sweeper', this.loadOceanSweeper.bind(this)]
  ]);

  public constructor(private resolver: ComponentFactoryResolver, private changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterViewInit() {
    this.loadComponent();
  }

  private getFirstChildTag(): string {
    const container = this.container.element.nativeElement;
    const children = container.children;

    if(children.length > 0) {
      return children[0].tagName.toLowerCase();
    }

    return '';
  }

  private getTagFromName(name: string): HTMLElement | null{
    const container = this.container.element.nativeElement;
    const children = container.children;

    for(let i = 0; i < children.length; i++) {
      if(children[i].tagName.toLowerCase() === name.toLowerCase()) {
        return children[i] as HTMLElement;
      }
    }

    return null;
  }

  public loadComponent() {    
    const tag = this.getFirstChildTag();
    const loader = this.puzzleMap.get(tag);

    if (loader) {
      loader().then(componentRef => {
        componentRef.instance.submitFreeformAnswer = this.submitFreeformAnswer;
        const childTags = this.getTagFromName(this.getFirstChildTag());

        if (childTags) {
          componentRef.instance.dynamicChildren = childTags;
        }

        // since we're jank-style loading components we need to manually trigger change detection
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  private async loadFarmPuzzle(): Promise<ComponentRef<PuzzleBaseComponent>> {
    const result = await import('./../farm-puzzle/pixi-puzzle-farm.component');
    const factory = this.resolver.resolveComponentFactory(result.PuzzleFarm);
    const componentRef = this.container.createComponent(factory);

    return componentRef;
  }

  private async loadOceanSweeper(): Promise<ComponentRef<PuzzleBaseComponent>> {
    const result = await import('./../../ocean-sweeper/ocean-sweeper.component');
    const factory = this.resolver.resolveComponentFactory(result.OceanSweeperComponent);
    const componentRef = this.container.createComponent(factory);

    return componentRef;
  }
}
