import { Injectable } from '@angular/core';

import { environment } from 'env/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  public buildServerApiUrl(...parts: string[]): string {
    return [environment.APIEndpoint]
      .concat(parts)
      .join('/');
  }

  public buildGameServerApiUrl(...parts: string[]): string {
    return [environment.GameAPIEndpoint]
      .concat(parts)
      .join('/');
  }
}
