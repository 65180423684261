import { AuthenticationService } from 'app/core/authentication/services/authentication.service';
import { Database, getDatabase, onValue, orderByChild, query, ref } from 'firebase/database';
import { DatabaseProviderService } from './database-provider.service';
import { filter, first, switchMap, tap } from 'rxjs/operators';
//TODO: changed 
//import { fromPromise } from 'rxjs/internal-compatibility'; 
import { from as fromPromise} from 'rxjs';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import { HttpClient } from '@angular/common/http';
import { IFirebaseConfigModel } from './firebase-config.model';
import { initializeApp } from 'firebase/app';
import { Injectable } from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { UrlService } from '../../http/url.service';

@Injectable()
export class FirebaseProviderService extends DatabaseProviderService {
  private basePath!: string;
  private serverTimeOffset!: number;
  private isLogged$ = new Subject<void>();
  private isInitialized$ = new Subject<void>();
  private database!: Database;
  private isLoggedIn = false;
  private isInitialized = false;

  public constructor(
    private readonly http: HttpClient,
    private readonly urlService: UrlService,
    private readonly authenticationService: AuthenticationService
  ) {
    super();
    this.initialize();
  }

  public getDate(): Date {
    return new Date(Date.now() + this.serverTimeOffset);
  }

  public getObject<T>(path: string): Observable<T | null> {
    const data$ = new Observable<T>(subscriber => {
      const dataRef = ref(this.database, this.calculateAbsolutePath(path));
      const onValueSubscription = onValue(dataRef, snapshot => {
        subscriber.next(snapshot.val())
      });
      subscriber.add(onValueSubscription);
    });

    return data$;
  }

  public getCollection<T>(path: string, orderByField: string): Observable<T[] | null> {
    const data$ = new Observable<T[]>(subscriber => {
      const dataRef = ref(this.database, this.calculateAbsolutePath(path));
      const dataQuery = query(dataRef, orderByChild(orderByField));
      const onValueSubscription = onValue(dataQuery, snapshot => {
        const result: T[] = [];
        // Need to use forEach to get a sorted list
        snapshot.forEach(child => {
          result.push(child.val());
        })

        subscriber.next(result);
      });

      subscriber.add(onValueSubscription);
    });

    return data$;
  }

  public whenLoggedIn(): Observable<void | boolean> {
    return this.isLoggedIn ? of(true) : this.isLogged$;
  }

  public whenInitialized(): Observable<void | boolean> {
    return this.isInitialized ? of(true) : this.isInitialized$;
  }

  private initialize(): void {
    this.getFirebaseConfig()
      .pipe(first())
      .subscribe(config => {
        this.initializeDatabase(config);

        this.subscribeUserLogIn();

        this.authenticationService.userLoggedOut()
          .pipe(first())
          .subscribe(() => this.logOut());
      });
  }

  private initializeDatabase(config: IFirebaseConfigModel): void {
    this.basePath = config.BasePath;

    const app = initializeApp({
      apiKey: config.ApiKey,
      databaseURL: config.DatabaseUrl
    });

    this.database = getDatabase(app);
    this.isInitialized = true;
  }

  private subscribeUserLogIn(): void {
    const isAuthorize$ = this.authenticationService.isAuthorized()
      .pipe(filter((isAuthorized: boolean) => isAuthorized));

    merge(isAuthorize$, this.authenticationService.userLoggedIn())
      .pipe(
        switchMap(() => this.logInFirebase()),
        tap(() => this.subscribeServerDate()),
        first()
      )
      .subscribe();
  }

  private logInFirebase(): Observable<any> {
    return this.getFirebaseConfig()
      .pipe(
        filter(config => !!config.AuthToken),
        switchMap(config => {
          this.isLoggedIn = true;
          const auth = getAuth();

          return fromPromise(signInWithCustomToken(auth, config.AuthToken as string));
        }),
        tap(() => this.isLogged$.next())
      );
  }

  private getFirebaseConfig(): Observable<IFirebaseConfigModel> {
    const url = this.urlService.buildServerApiUrl('Login', 'Firebase');

    return this.http.post<IFirebaseConfigModel>(url, {})
  }

  private logOut(): void {
    signOut(getAuth());
  }

  private subscribeServerDate(): void {
    const dataRef = ref(this.database, '.info/serverTimeOffset');
    onValue(dataRef, snapshot => {
      this.serverTimeOffset = snapshot.val();
    });
  }

  private calculateAbsolutePath(path: string): string {
    return (path[0] === '.' ? '' : this.basePath) + path;
  }
}
