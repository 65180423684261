import { AbstractControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

import { TEMPLATE_PREFIX } from 'env/locale-config';

@Component({
  selector: 'kh-input',
  styleUrls: ['./input.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'input.component.html'
})
export class InputComponent implements OnInit {

  @Input() public controlValue: AbstractControl;
  @Input() public controlType = 'text';
  @Input() public placeholder = '';

  public hasValidation = false;

  public ngOnInit(): void {
    this.hasValidation = !!this.controlValue.validator;
  }
}
