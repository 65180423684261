import { Component, Input, Inject } from '@angular/core';
import { IScorable, ScorableData } from "../kh-manip-scorable/IScorable";
import { DynamicContentChild, OnDynamicData, OnDynamicMount } from "ngx-dynamic-hooks";
import { KhManipScorableIntInput } from "../kh-manip-scorable-int-input/kh-manip-scorable-int-input.component";
import { KhManipulativeWindowComponent } from "../kh-manip-scorable-window/kh-manip-scorable-window.component";
import { KhManipulativeWindowControlsComponent } from "../kh-manip-scorable-window-controls/kh-manip-scorable-window-controls.component";
import { BehaviorSubject } from "rxjs";
import { KhManipScorableInput } from "../kh-manip-scorable-input/kh-manip-scorable-input.component";
import { ControlType } from "../../../enums/control-type.enum";
import { ManipulativeRegionType } from "../../../enums/manipulative-region-type.enum";
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../../../services/IQuestionEvents';

@Component({
  selector: "kh-manipulative-windows",
  templateUrl: "kh-manip-scorable-windows.component.html",
  styleUrls: ["kh-manip-scorable-windows.component.less"],
})
export class KhManipulativeWindowsComponent implements IScorable, OnDynamicMount {
  @Input() public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @Input("frame") public frame = "0 0 100 100";
  @Input("backgroundImage") public backgroundImage: string = "";
  public scorableData: ScorableData = { answer: {}, state: {} };
  public contentChild!: DynamicContentChild[];
  public regionsData: ScorableData[] = [];
  public selectedPaneId!: string;
  public box = { left: 0, top: 0, width: 0, height: 0, };
  public data$ = new BehaviorSubject<ScorableData>({ answer: {}, state: {} });

  public ngOnInit() {
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    this.box = { left, top, width, height };
  }

  public constructor(@Inject(QUESTION_EVENTS_SERVICE_TOKEN) readonly questionEventsService: IQuestionEventsService) {
    this.questionEventsService.hideInputOnlyForManip.next(true);
  }

  public onDynamicMount(data: OnDynamicData): void {
    const windowsContentChildren: DynamicContentChild[] = data.contentChildren ?? [];
    this.contentChild = windowsContentChildren;

    windowsContentChildren.forEach((c, _index) => {
      if (c.componentRef.instance instanceof KhManipulativeWindowComponent) {
        const curWindowComponent = c.componentRef.instance;
        this.selectedPaneId = this.scorableData.state[curWindowComponent.id];
        if (!this.selectedPaneId) {
          this.data$.subscribe((data) => {
            this.selectedPaneId = data.state[curWindowComponent.id];
            const windowContentChildren: DynamicContentChild[] = c.contentChildren ?? [];
            for (let i = 0; i < windowContentChildren.length; i++) {
              const curPaneComponent = windowContentChildren[i].componentRef.instance;
              if (curPaneComponent.id == this.selectedPaneId) {
                curPaneComponent.visibility = 'visible';
              }
              else {
                curPaneComponent.visibility = 'hidden';
              }
            }
          });
        }
        else {
          const windowContentChildren: DynamicContentChild[] = c.contentChildren ?? [];
          for (let i = 0; i < windowContentChildren.length; i++) {
            const curPaneComponent = windowContentChildren[i].componentRef.instance;
            if (curPaneComponent.id == this.selectedPaneId) {
              curPaneComponent.visibility = 'visible';
            }
            else {
              curPaneComponent.visibility = 'hidden';
            }
          }
        }
      } else if (c.componentRef.instance instanceof KhManipulativeWindowControlsComponent) {
        const curComponent = c.componentRef.instance;
        curComponent.clickCallback = this.onClickChangeValue.bind(this);
      } else if (c.componentRef.instance instanceof KhManipScorableIntInput) {
        const curComponent = c.componentRef.instance;
        curComponent.value = this.scorableData.state[curComponent.key] ?? "";
        curComponent.onChangeCallback = this.onIntInputChangeValue.bind(this);
      } else if (c.componentRef.instance instanceof KhManipScorableInput) {
        const curComponent = c.componentRef.instance;
        curComponent.value = this.scorableData.state[curComponent.key] ?? "";
        curComponent.onChangeCallback = this.onInputChangeValue.bind(this);
      }
    });
  }

  public onClickChangeValue(val: any): void {
    this.setAnswerAndState(val, ManipulativeRegionType.MANIP_WINDOW_CONTROLS);
    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  public onIntInputChangeValue(val: any): void {
    this.setAnswerAndState(val, ManipulativeRegionType.MANIP_INT_INPUT_REGION);
    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  public onInputChangeValue(val: any): void {
    this.setAnswerAndState(val, ManipulativeRegionType.MANIP_INPUT_REGION);
    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  public getScorableData(): ScorableData {
    const data: ScorableData = {
      answer: this.scorableData.answer,
      state: this.scorableData.state,
    };
    this.data$.next(data);
    return data;
  }

  public setScorableData(data: ScorableData): void {
    this.scorableData = data;
  }

  private setAnswerAndState(data: ScorableData, manipulativeType: ManipulativeRegionType): void {
    const currentRegionAnswerKey = Object.keys(data.answer)[0];
    const currentRegionAnswerValue = Object.values(data.answer)[0];
    const currentRegionStateKey = Object.keys(data.state)[0];
    const currentRegionStateValue = Object.values(data.state)[0];
    const answers = Object.keys(this.scorableData.answer);
    if (manipulativeType === ManipulativeRegionType.MANIP_WINDOW_CONTROLS) {// for Window controls manipulative
      const currentStateControllerId = Object.keys(data.state)[0];
      const currentWindowContent = this.contentChild.find(c => c.componentRef.instance.controllers === currentStateControllerId)?.componentRef.instance;
      const currentAnswerWindowId = currentWindowContent.id;
      const currentAnswerWindowStartID = currentWindowContent.startID;
      let nextContent;
      if (currentRegionAnswerValue === ControlType.FORWARD || currentRegionAnswerValue === ControlType.BACKWARD) {
        let stateIndex = 0;
        if (answers.includes(currentAnswerWindowId)) {
          stateIndex = currentWindowContent.contentChild.findIndex((item: any) => item.componentRef.instance.id == this.scorableData.state[currentAnswerWindowId]);
          if (currentRegionAnswerValue === ControlType.FORWARD) {
            nextContent = currentWindowContent.contentChild[stateIndex + 1];
          } else if (currentRegionAnswerValue === ControlType.BACKWARD) {
            nextContent = currentWindowContent.contentChild[stateIndex - 1];
          }
        }
        else {
          stateIndex = currentAnswerWindowStartID ? currentWindowContent.contentChild.findIndex((item: any) => item.componentRef.instance.order == currentAnswerWindowStartID) : 0;
          nextContent = currentWindowContent.contentChild[stateIndex];
        }
        if (nextContent) {
          if(nextContent.componentRef?.instance?.ignoreInScore?.toString() !== 'true') {
          this.scorableData.answer[currentAnswerWindowId] = nextContent.componentRef.instance.id;
          }
          else{
            delete this.scorableData.answer[currentAnswerWindowId];
          }
          this.scorableData.state[currentAnswerWindowId] = nextContent.componentRef.instance.id;
        }
      }
    } else if (manipulativeType === ManipulativeRegionType.MANIP_INT_INPUT_REGION || manipulativeType === ManipulativeRegionType.MANIP_INPUT_REGION) {// for Int Input manipulative
      if (currentRegionAnswerValue?.length > 0) {
        this.scorableData.answer[currentRegionAnswerKey] = currentRegionAnswerValue;
        this.scorableData.state[currentRegionStateKey] = currentRegionStateValue;
      }
      else {
        delete this.scorableData.answer[currentRegionAnswerKey];
        delete this.scorableData.state[currentRegionStateKey];
      }
    }
  }
}
