import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'unsafeHtml'
})
export class UnsafeHtmlPipe implements PipeTransform {

  constructor(private readonly domSanitizer: DomSanitizer) {
  }

  transform(value: string | undefined): SafeHtml {
    if (value === undefined) {
      return '';
    }

    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

}
