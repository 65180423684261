import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TEMPLATE_PREFIX } from 'env/locale-config';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'kh-upload-files-listing-modal',
  templateUrl: TEMPLATE_PREFIX + 'upload-files-listing-modal.component.html',
  styleUrls: ['./upload-files-listing-modal.component.less']
})
export class UploadFilesListingModalComponent {
  @Input() public imagePath = '';
  @Input() public track = 'ui-tinymce-1';
  @Input() public lang?: string;
  @ViewChild('link', {read: ElementRef}) public link!: ElementRef<HTMLInputElement>;

  @Output() public linkSet = new EventEmitter<string>();

  constructor(private readonly bsModalRef: BsModalRef) {}

  public get source(): string {
    return `/tinyfilemanager.net/dialog.aspx?type=1&editor=${this.track}&lang=${this.lang}&currpath=${this.imagePath}`;
  }

  public closeModal(): void {
    this.linkSet.emit(this.link.nativeElement.value);
    this.bsModalRef.hide();
  }
}