<quill-editor [modules]="modules"
              trackChanges="user"
              [format]="editorFormat"
              [ngModel]="text"
              (ngModelChange)="changeValue($event)"
              class="tinymce-editor GsEditField QuestionText d-block"
              (onEditorCreated)="editorCreated($event)"
              (onSelectionChanged)="selectionChanged($event)"
              placeholder="Insert text here..."
              localize-attributes="placeholder" [ngClass]="getEditorClass()">
  <div quill-editor-toolbar>
    <span class="ql-formats" [class.untouchable]="isCodeMode()">
      <select class="ql-size">
        <option value="small"></option>
        <option selected></option>
        <option value="large"></option>
        <option value="huge"></option>
      </select>
    </span>
    <span class="ql-formats" [class.untouchable]="isCodeMode()">
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
    </span>
    <span class="ql-formats" [class.untouchable]="isCodeMode()">
      <select class="ql-color"></select>
      <select class="ql-background"></select>
    </span>
    <span class="ql-formats" [class.untouchable]="isCodeMode()">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <select class="ql-align">
        <option selected></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>
    </span>
    <span class="ql-formats" [class.untouchable]="isCodeMode()">
      <button class="ql-link"></button>
      <button class="ql-upload-file" [class.ql-active]="isImageSelected"
              (click)="uploadClicked($event)">
        <fa-icon [icon]="['fas', 'photo-video']"></fa-icon>
      </button>
      <button class="ql-format" [class.ql-active]="isCodeMode()" (click)="changeEditorFormat()">
        <fa-icon [icon]="['fas', 'code']"></fa-icon>
      </button>
    </span>
  </div>
</quill-editor>
<kh-upload-file-modals-holder *ngIf="showUpload"
                              [sourceImage]="sourceImage"
                              [imagePath]="imagePath"
                              (hideModals)="hideModals($event)"></kh-upload-file-modals-holder>
