import {
  Component,
  Input,
} from "@angular/core";
import { ScorableData } from "../kh-manip-scorable/IScorable";
import { DynamicContentChild, OnDynamicData, OnDynamicMount } from "ngx-dynamic-hooks";
@Component({
  selector: "kh-manipulative-window-controls",
  templateUrl: "kh-manip-scorable-window-controls.component.html",
  styleUrls: ["kh-manip-scorable-window-controls.component.less"],
})
export class KhManipulativeWindowControlsComponent implements OnDynamicMount {
  @Input("id") public id: string = "";
  @Input("background") public background: string = "";
  public clickCallback: undefined | ((data: ScorableData) => void);
  public contentChild!: DynamicContentChild[];

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];
    this.contentChild = contentChildren;
    contentChildren.forEach((c, index) => {
      const curComponent = c.componentRef.instance;
      curComponent.id = this.id;
      curComponent.clickCallback = this.onChangeValue.bind(this);
    });
  }

  public onChangeValue(val: ScorableData): void {
    if (this.clickCallback) {
      this.clickCallback(
        this.getRegionScorableData(val)
      );
    }
  }

  public getRegionScorableData(val: ScorableData) {
    const data: ScorableData = {
      answer: { [this.id]: val.answer[this.id] },
      state: { [this.id]: val.state[this.id] },
    }

    return data;
  }
}
