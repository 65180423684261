<div class="modal-heading modal-heading-1">
	<div class="label_name">Upload Solution</div>
</div>

<div class="modal-body p-0">
	<div class="upload-modal__option" (click)="useWebCam()">
		<div class="upload-modal__option-title">Use Webcam</div>
		<div class="upload-modal__option-subtitle">Chromebooks, laptops</div>
	</div>
	<div class="upload-modal__option" (click)="fileInput.click()">
		<div class="upload-modal__option-title">Phone Camera</div>
		<div class="upload-modal__option-subtitle">Phones, tablets, or <a class="ul">upload a file</a></div>
	</div>
</div>

<div class="modal-footer upload-modal__footer justify-content-end">
	<button type="button" class="btn btn-default" (click)="cancel()" data-dismiss="modal">Cancel</button>
</div>

<input class="d-none" type="file" (change)="uploadFile($event)" #fileInput>
