import { catchError } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthenticationService } from '../authentication';
import { DefaultPageRouteService, RouterService } from '../navigation';
import { HTTP_STATUS_CODES } from '../app-constants/http-status-codes';

@UntilDestroy()
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  public constructor(
    private authService: AuthenticationService,
    private readonly routerService: RouterService,
    private readonly defaultRotingService: DefaultPageRouteService) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError(error => this.handleErrors(request, error)));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleErrors(request: HttpRequest<any>, error: HttpErrorResponse): Observable<any> {
    if (error instanceof HttpErrorResponse) {
      if (error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
        if (error.error?.error !== 'invalid_grant') {
          // if error is invalid_grant then user is in the process of logging in, so no need to handle log out here
          this.redirectOnUnauthorized();

          return of();
        }
      }

      if (error.status === HTTP_STATUS_CODES.FORBIDDEN) {
        this.authService.isAuthorized()
          .pipe(untilDestroyed(this))
          .subscribe(isAuthorized => {
            if (isAuthorized) {
              this.authService.setInterruptedUrl(this.routerService.getUrl());
            }
            this.redirectOnUnauthorized();
          });

        return of();
      }
    }

    return throwError(error);
  }

  public redirectOnUnauthorized(): void {
    this.authService.logOut();
    this.defaultRotingService.navigateToPublicFallback();
  }
}
