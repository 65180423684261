import { Component } from '@angular/core';

import { TEMPLATE_PREFIX } from 'env/locale-config';

@Component({
  selector: 'kh-empty-layout',
  styleUrls: ['./empty-layout.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'empty-layout.component.html'
})
export class EmptyLayoutComponent {

}
