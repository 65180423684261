<div class="modal-heading modal-heading-1 d-flex align-items-center justify-content-between mce-tinyfilemanager.net">
  <div class="label_name">Image Manager</div>
  <button class="mce-close btn btn-link" (click)="closeModal()">
    <fa-icon [icon]="['fas', 'times']"></fa-icon>
  </button>
</div>

<div class="modal-body p-0">
  <div class="link-field-wrapper">
    <input #link class="link-field mce-img_{{track}}" type="text" />
  </div>
  <iframe id="uploader-frame" class="uploader-container" [src]="source | unsafeUrl"></iframe>
</div>
