import { Component, Input } from '@angular/core';
import { getAssetsPath } from 'app/core/kh-utils/kh-utils';

import { TEMPLATE_PREFIX } from 'env/locale-config';

@Component({
  selector: 'kh-img',
  styleUrls: ['./img.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'img.component.html'
})
export class ImgComponent {
  @Input() public addAssetsPath = true;
  @Input() public set path(imagePath: string) {
    this.imagePath = this.addAssetsPath
      ? getAssetsPath(imagePath)
      : imagePath;
  }

  public get path(): string {
    return this.imagePath;
  }

  @Input() public alt = '';
  @Input() public cssClass = '';
  @Input() public cssStyles: { [key: string]: string } = {};

  public imagePath!: string;
}
