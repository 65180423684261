<div>
  <button class="btn btn-info upload-solution-button" *ngIf="!isWebCamActive && !isWebCamAvailable" (click)="fileInput.click()">
    <div class="upload-solution-button__icon">
      <fa-icon [icon]="['fas', 'camera']"></fa-icon>
    </div>
    <div class="text-sm">Show Your Work</div>
  </button>
  <input class="d-none" type="file" (change)="selectFile($event)" #fileInput>

  <div [hidden]="!isWebCamAvailable">
    <button class="btn btn-info upload-solution-button" *ngIf="!isWebCamActive" (click)="selectImageSource()">
      <div class="upload-solution-button__icon">
        <fa-icon [icon]="['fas','camera']"></fa-icon>
      </div>
      <div class="text-sm">Show Your Work</div>
    </button>

    <div class="snapshot-container" [hidden]="!isWebCamActive">

      <header class="snapshot-header">
        <div class="container center">
          <button class="btn btn-info btn-lg" *ngIf="!isPreviewDisplayed" (click)="takeSnapshot()">Take Photo</button>
          <button class="btn btn-default btn-lg" *ngIf="isPreviewDisplayed" (click)="retrySnapshot()">
            <div class="d-flex">
              <fa-icon class="d-none d-sm-block" [icon]="['fas','redo']"></fa-icon> &nbsp;Retry
            </div>
          </button>
          <button class="btn btn-info btn-lg" *ngIf="isPreviewDisplayed" (click)="uploadSnapshot()">
            <div class="d-flex">
              <fa-icon class="d-none d-sm-block" [icon]="['fas','check']"></fa-icon> &nbsp;Accept
            </div>
          </button>
          <a class="snapshot-cancel-link cursor" (click)="cancelSnapshot()">
            Cancel
          </a>
        </div>
      </header>

      <div class="snapshot-body">
        <video #cameraView class="w-100 h-100" [hidden]="isPreviewDisplayed"></video>
        <img #photoCanvas class="snapshot-photo-canvas" [hidden]="!isPreviewDisplayed"/>
      </div>
    </div>
  </div>
</div>
