<input [type]="controlType" *ngIf="controlType !== 'checkbox'"
       [formControl]="controlValue"
       autocomplete="off" autocorrect="off" autocapitalize="off"
       class="form-control input"
       [ngClass]="{'error': controlValue.touched && controlValue.errors, 'field-verified': controlValue.valid && hasValidation }"
       [placeholder]="placeholder" />

<input type="checkbox" *ngIf="controlType === 'checkbox'"
       [formControl]="controlValue"
       [ngClass]="{'error': controlValue.touched && controlValue.errors, 'field-verified': controlValue.valid && hasValidation }" />
