import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Player from '@vimeo/player';
import { VideoDataService } from '../video-data.service';

import { IVideoModel } from './models';
import { VIDEO_HOST } from './video-host';

import { TEMPLATE_PREFIX } from 'env/locale-config';

@UntilDestroy()
@Component({
  selector: 'kh-video-with-events',
  styleUrls: ['./video-with-events.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'video-with-events.component.html'
})
export class VideoWithEventsComponent implements OnInit, AfterViewInit {

  @ViewChild('playerId') public playerContainer!: ElementRef;

  @Input() public videoId = '';
  @Input() public attemptid = '';
  @Input() public attemptitemorder = 0;
  @Input() public trainingcontentid = '';
  @Input() public videotype = '';

  private player!: Player;
  private isViewInit = false;
  public video!: IVideoModel;
  private videoPosition = 0;

  public constructor(
    private readonly service: VideoDataService) {
  }

  public ngOnInit(): void {
    this.service.getVideo(this.videoId)
      .pipe(untilDestroyed(this))
      .subscribe(video => {
        this.video = video;
        this.initPlayer();
      });
  }

  public ngAfterViewInit(): void {
    this.isViewInit = true;
    this.initPlayer();
  }

  private initPlayer(): void {
    if(this.video && this.isViewInit) {
      switch(this.video.VideoHost) {
        case VIDEO_HOST.VIMEO: {
          this.player = new Player(this.playerContainer.nativeElement, { id: +this.video.ExternalVideoId });
          this.player.on('play', () => this.onPlay());
          this.player.on('pause', () => this.onPause());
          this.player.on('finish', () => this.onFinish());
          this.player.on('seeked', () => this.onSeek());
          this.player.on('timeupdate', (data : {seconds: number}) => this.onTimeUpdate(data.seconds));
          break;
        }
      }
    }
  }

  private onPlay(): void {
    this.logEvent('P');
  }

  private onPause(): void {
    this.logEvent('U');
  }

  private onFinish(): void {
    this.logEvent('F');
  }

  private onSeek(): void {
    this.logEvent('S');
  }

  private onTimeUpdate(currentSeconds: number): void {
    this.videoPosition = currentSeconds;
  }

  private logEvent(type: string): void {
    this.service.saveVideoEvent(this.videoId, {
      AttemptId: this.attemptid,
      AttemptItemOrder: this.attemptitemorder,
      EventPosition: this.videoPosition,
      EventType: type,
      TrainingContentId: this.trainingcontentid,
      VideoType: this.videotype })
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
