import * as _ from 'lodash-es';
import { APP_ROUTE } from '../../../app-routing.constant';
import { Injectable } from '@angular/core';

import { AuthenticationService, PermissionsService, UserRole } from '../../authentication';
import { DEFAULT_PAGE_ROLE_MAPPING, NAVIGATION_URLS } from '../constants';
import { Router } from '@angular/router';
import { RouterService } from 'app/core/navigation';
import { ACCESS_ROUTE } from '../../../access/access-routing.constant';

@Injectable({
  providedIn: 'root'
})
export class DefaultPageRouteService {
  private previousRedirectKey = 'kh_previous_redirect';

  public constructor(
    private readonly authService: AuthenticationService,
    private readonly permissionsService: PermissionsService,
    private readonly router: Router,
    private readonly routerService: RouterService) { }

  public isAuthorized(): boolean {
    const roles = this.authService.getUserClaims()?.roles;
    let isRoleFound = false;
    if (roles) {
      for (const role of roles) {
        const defaultRoute = DEFAULT_PAGE_ROLE_MAPPING[role];
        if (defaultRoute) {
          return true;
        }
      }
    }
    return isRoleFound;
  }
  public routeToUnAuthorizedPage(): void {
    this.router.navigate([ACCESS_ROUTE.UNAUTHORIZED]);
  }

  public routeToDefaultPage(): void {
    const roles = this.authService.getUserClaims()?.roles;
    if (roles) {
      for (const role of roles) {
        const defaultRoute = DEFAULT_PAGE_ROLE_MAPPING[role];
        if (defaultRoute) {
          this.router.navigate(defaultRoute.state);

          return;
        }
      }

      if (_.includes(roles, UserRole.viewOnlyParent)) {
        this.authService.logOut()
        window.location.href = NAVIGATION_URLS.VIEW_ONLY_PARENT_URL;
      }
    }
  }

  public getCurrentFallbackPage(): string {
    if (this.permissionsService.isInRole(UserRole.student)) {
      return NAVIGATION_URLS.STUDENT_FALLBACK_PAGE;
    }

    return NAVIGATION_URLS.PUBLIC_FALLBACK_PAGE;
  }

  public navigateToPublicFallback(): void {
    const interruptedUrl = this.authService.getInterruptedUrl();
    const previousRedirect = localStorage.getItem(this.previousRedirectKey);
    const fallbackUrl = this.getCurrentFallbackPage();

    if (interruptedUrl.split('?')[0].toLowerCase() === fallbackUrl.toLowerCase()) {
      this.authService.setInterruptedUrl('');
      location.href = location.href.split('?')[0];
      localStorage.removeItem(this.previousRedirectKey);
      this.router.navigateByUrl(`${fallbackUrl}`, { replaceUrl: true });

      return;
    }

    if (interruptedUrl && this.routerService.IsExternalGuarded(fallbackUrl)) {
      //TODO: check RFA
      /*this.router.navigate(APP_ROUTE.RFA.route(),
        { queryParams: APP_ROUTE.RFA.queryParams(fallbackUrl, interruptedUrl), replaceUrl: true });*/

      return;
    }

    if (interruptedUrl && previousRedirect !== interruptedUrl) {
      localStorage.setItem(this.previousRedirectKey, interruptedUrl);
      this.router.navigateByUrl(`${fallbackUrl}?RedirectFromUrl=${interruptedUrl}`, { replaceUrl: true });
    }
    else {
      localStorage.setItem(this.previousRedirectKey, '');
      window.location.href = fallbackUrl;
    }
  }
}
