import {
  faApple as fabApple,
  faCcAmex as fabCcAmex,
  faCcMastercard as fabCcMastercard,
  faCcVisa as fabCcVisa,
  faGooglePlay as fabGooglePlay,
  faTelegramPlane as fabTelegramPlane
} from '@fortawesome/free-brands-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import {
  faBell as farBell,
  faCheckSquare as farCheckSquare,
  faCircle as farCircle,
  faClock as farClock,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faHeart as farHeart,
  faPlayCircle as farPlayCircle,
  faThumbsDown as farThumbsDown,
  faThumbsUp as farThumbsUp,
  faTimesCircle as farTimesCircle
} from '@fortawesome/free-regular-svg-icons';

import {
  faAngleDown as fasAngleDown,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faAngleUp as fasAngleUp,
  faAppleAlt as fasAppleAlt,
  faArrowDown as fasArrowDown,
  faArrowUp as fasArrowUp,
  faAsterisk as fasAsterisk,
  faBars as fasBars,
  faBolt as fasBolt,
  faBook as fasBook,
  faBookmark as fasBookmark,
  faCalendar as fasCalendar,
  faCalendarCheck as fasCalendarCheck,
  faCamera as fasCamera,
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faChalkboard as fasChalkboard,
  faChalkboardTeacher as fasChalkboardTeacher,
  faChartBar as fasChartBar,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircle as fasCircle,
  faClipboardCheck as fasClipboardCheck,
  faClock as fasClock,
  faClone as fasClone,
  faCloudDownloadAlt as fasCloudDownloadAlt,
  faCode as fasCode,
  faCog as fasCog,
  faCommentAlt as fasCommentAlt,
  faCompress as fasCompress,
  faCopy as fasCopy,
  faDownload as fasDownload,
  faEllipsisH as fasEllipsisH,
  faEnvelope as fasEnvelope,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExpand as fasExpand,
  faExpandAlt as fasExpandAlt,
  faExternalLinkAlt as fasExternalLinkAlt,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faFile as fasFile,
  faFileAlt as fasFileAlt,
  faFilePdf as fasFilePdf,
  faFire as fasFire,
  faFlag as fasFlag,
  faFolder as fasFolder,
  faFolderPlus as fasFolderPlus,
  faGraduationCap as fasGraduationCap,
  faShareSquare,
  faHeadphones as fasHeadphones,
  faHeart as fasHeart,
  faHome as fasHome,
  faInfo as fasInfo,
  faInfoCircle as fasInfoCircle,
  faLeaf as fasLeaf,
  faLightbulb as fasLightbulb,
  faLink as fasLink,
  faListOl as fasListOl,
  faLock as fasLock,
  faLongArrowAltLeft as fasLongArrowAltLeft,
  faMinus as fasMinus,
  faMobileAlt as fasMobileAlt,
  faPager as fasPager,
  faPaperPlane as fasPaperPlane,
  faPencilAlt as fasPencilAlt,
  faPhotoVideo as fasPhotoVideo,
  faPlay as fasPlay,
  faPlus as fasPlus,
  faPlusCircle as fasPlusCircle,
  faPrint as fasPrint,
  faQuestionCircle as fasQuestionCircle,
  faRedo as fasRedo,
  faRedoAlt as fasRedoAlt,
  faRocket as fasRocket,
  faRulerHorizontal as fasRulerHorizontal,
  faSearch as fasSearch,
  faSearchPlus as fasSearchPlus,
  faShareAlt as fasShareAlt,
  faShareSquare as fasShareSquare,
  faSignal as fasSignal,
  faSortDown as fasSortDown,
  faSquare as fasSquare,
  faStar as fasStar,
  faStarHalfAlt as fasStarHalfAlt,
  faSyncAlt as fasSyncAlt,
  faTh as fasTh,
  faThList as fasThList,
  faThumbsUp as fasThumbsUp,
  faTimes as fasTimes,
  faTrashAlt as fasTrashAlt,
  faTrophy as fasTrophy,
  faUnlink as fasUnlink,
  faUpload as fasUpload,
  faUser as fasUser,
  faUserFriends as fasUserFriends,
  faVolumeMute as fasVolumeMute,
  faVolumeUp as fasVolumeUp
} from '@fortawesome/free-solid-svg-icons';

export const faIcons: IconDefinition[] = [
  fasAngleDown,
  fasAngleRight,
  fasAngleUp,
  fasAngleLeft,
  fasAppleAlt,
  fasArrowDown,
  fasArrowUp,
  fasAsterisk,
  fasBars,
  fasBolt,
  fasBook,
  fasBookmark,
  fasCamera,
  fasCaretDown,
  fasCaretRight,
  fasCalendar,
  fasCalendarCheck,
  fasChartBar,
  fasChalkboardTeacher,
  fasCommentAlt,
  fasCompress,
  fasCode,
  fasCog,
  fasCompress,
  fasCopy,
  fasClipboardCheck,
  fasClock,
  fasCircle,
  fasChalkboard,
  fasCheck,
  fasCheckCircle,
  fasChevronUp,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasAngleRight,
  fasEllipsisH,
  fasClipboardCheck,
  fasCloudDownloadAlt,
  fasClone,
  fasCommentAlt,
  fasEnvelope,
  fasEye,
  fasEyeSlash,
  fasExpand,
  fasExpandAlt,
  fasExclamationCircle,
  fasExclamationTriangle,
  fasExternalLinkAlt,
  fasFlag,
  fasFile,
  fasFire,
  fasFolder,
  fasFolderPlus,
  fasPrint,
  fasQuestionCircle,
  fasGraduationCap,
  fasFileAlt,
  fasFilePdf,
  fasFlag,
  fasHeart,
  fasHome,
  fasHeadphones,
  fasInfo,
  fasInfoCircle,
  fasLeaf,
  fasLongArrowAltLeft,
  fasLightbulb,
  fasLink,
  fasListOl,
  fasLock,
  fasMinus,
  fasMobileAlt,
  fasPager,
  fasPaperPlane,
  fasPencilAlt,
  fasPhotoVideo,
  fasPlay,
  fasPlus,
  fasPlusCircle,
  fasRedo,
  fasRedoAlt,
  fasRocket,
  fasRulerHorizontal,
  fasSearch,
  fasSearchPlus,
  fasShareAlt,
  fasShareSquare,
  fasSortDown,
  fasShareSquare,
  fasSignal,
  fasSquare,
  fasStar,
  fasStarHalfAlt,
  fasSyncAlt,
  fasTimes,
  fasTh,
  fasThList,
  fasThumbsUp,
  fasTrashAlt,
  fasTrophy,
  fasUnlink,
  fasUpload,
  fasUser,
  fasUserFriends,
  fasVolumeUp,
  fasVolumeMute,
  fasDownload,
  farBell,
  farCheckSquare,
  farCircle,
  farCheckSquare,
  farClock,
  farEye,
  farEyeSlash,
  farHeart,
  farPlayCircle,
  farTimesCircle,
  farThumbsUp,
  farThumbsDown,
  fabApple,
  fabCcAmex,
  fabCcMastercard,
  fabCcVisa,
  fabGooglePlay,
  fabTelegramPlane
];
