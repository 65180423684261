import * as _ from 'lodash-es';
import { filter } from 'rxjs/operators';
import { GuardsCheckEnd, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Route, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Logger } from 'app/core/logger/services/logger.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AppNavigationService {

  private isNavigating = false;
  private targetRouteUrl: string | null = null;
  private routeActive = false;

  public constructor(
    private readonly router: Router,
    private readonly logger: Logger)
  {
    this.handleRouterEvents();
  }

  public hasRoute(): boolean {
    return this.isNavigating;
  }

  private handleRouterEvents(): void {
    this.router.events.pipe(filter((event): event is NavigationStart => event instanceof NavigationStart))
      .pipe(untilDestroyed(this))
      .subscribe(this.initNavigation.bind(this));
    this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .pipe(untilDestroyed(this))
      .subscribe((event: NavigationEnd) => this.cleanNavigation(event.url));
    this.router.events.pipe(filter((event): event is GuardsCheckEnd => event instanceof GuardsCheckEnd))
      .pipe(untilDestroyed(this))
      .subscribe(this.setRouteActivation.bind(this));
    this.router.events.pipe(filter((event): event is NavigationCancel => event instanceof NavigationCancel))
      .pipe(untilDestroyed(this))
      .subscribe(this.cancelNavigation.bind(this));
    this.router.events.pipe(filter((event): event is NavigationError => event instanceof NavigationError))
      .pipe(untilDestroyed(this))
      .subscribe(error => {
        this.logger.error(`Navigation to ${error.url} failed`, error);
      });
  }

  private initNavigation(event: NavigationStart): void {
    if (!this.isNavigating) {
      this.isNavigating = true;
      this.targetRouteUrl = event.url;
      this.routeActive = false;
    }
  }

  private setRouteActivation(event: GuardsCheckEnd): void {
    if (this.targetRouteUrl === event.url) {
      this.routeActive = event.shouldActivate;
    }
  }

  private cancelNavigation(event: NavigationCancel): void {
    this.navigateToCurrentRoute(event.url);
    if (!this.routeActive) {
      this.cleanNavigation(event.url);
    }
  }

  private cleanNavigation(url: string): void {
    if (this.targetRouteUrl === url) {
      this.isNavigating = false;
      this.targetRouteUrl = '';
      this.routeActive = false;
    }
  }

  private navigateToCurrentRoute(url: string): void {
    if (this.targetRouteUrl === url && this.routeActive) {
      this.router.navigateByUrl(this.targetRouteUrl);
    }
  }

  private IsGuarded(route: Route): boolean {
    return !!route.canActivate && _.some(route.canActivate, guard => guard.name === 'ExternalLinkGuard');
  }

  public IsExternalGuarded(url: string): boolean {
    const urlsRoute = _.find(this.router.config, route => route.path === url.substr(1));
    if (!!urlsRoute &&
        (this.IsGuarded(urlsRoute) || (!!urlsRoute.children && _.some(urlsRoute.children, c => !c.path && this.IsGuarded(c))))) {

      return true;
    }

    return false;
  }
}
