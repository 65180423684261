import { APP_BASE_HREF } from '@angular/common';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AuthenticationDataService } from 'app/core/authentication';
import { LOCALE_CONFIG } from 'env/locale-config';
import { AppModule } from 'app/app.module';
import { environment } from './environments/environment';
import { buildLocaleUrl } from 'app/core/internationalization/locale-url-builder';

let suppressInitialization = false;
if (localStorage && window) {
  let userLocale = new AuthenticationDataService().getUserLocale();
  if (userLocale !== LOCALE_CONFIG.APP_LOCALE) {
    let newUrl = buildLocaleUrl(userLocale);
    if (newUrl != window.location.href) {
      suppressInitialization = true;
      window.location.href = newUrl;
    }
  }
}

if (!suppressInitialization) {
  const providers = [
    { provide: APP_BASE_HREF, useValue: environment.AppBaseHref + LOCALE_CONFIG.BASE_REF_SUFFIX }
  ];

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic(providers)
    .bootstrapModule(AppModule).then(a=> console.log("starting app"))
    .catch(err => console.error(err));
}
