import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { DefaultPageRouteService } from '../../navigation/services/default-page-route.service';

@Injectable()
export class PublicRedirectGuard implements CanActivate {
  public constructor(private authService: AuthenticationService,
    private defaultNavigation: DefaultPageRouteService) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.authService.isAuthorized()
      .pipe<boolean>(
        map<boolean, boolean>((isAuthorized: boolean): boolean => {
          if (isAuthorized) {
            this.defaultNavigation.routeToDefaultPage();

            return false;
          }

          return true;
        })
      );
  }

}
