import { Event, NavigationExtras, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppNavigationService } from './app-navigation.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class RouterService {
  public readonly events: Subject<Event> = new Subject<Event>();

  public constructor(
    private router: Router,
    private navigationService: AppNavigationService) {
    this.router.events
      .pipe(untilDestroyed(this))
      .subscribe(event => this.events.next(event));
  }

  public isActive(url: string | UrlTree, exact: boolean): boolean {
    return this.router.isActive(url, exact);
  }

  public getUrl(): string {
    return this.router.url;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.navigationService.hasRoute()
      ? Promise.resolve(false)
      : this.router.navigate(commands, extras);
  }

  public navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    return this.navigationService.hasRoute()
      ? Promise.resolve(false)
      : this.router.navigateByUrl(url, extras);
  }

  public IsExternalGuarded(url: string): boolean {
    return this.navigationService.IsExternalGuarded(url);
  }
}
