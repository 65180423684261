/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { EnvironmentService } from 'app/core/app-constants/environment.service';
import { ILog } from '../models/log';
import { LogLevel } from '../constants/log-level';
import { UrlService } from 'app/core/http/url.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class Logger {
  private static level: LogLevel = LogLevel.Debug;

  public static enableProductionMode(): void {
    Logger.level = LogLevel.Warning;
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly location: LocationStrategy,
    private readonly url: UrlService,
    private readonly environment: EnvironmentService) {
  }

  public debug(cause: string, value: any = undefined, message = '', stackTrace = ''): void {
    this.log(log => console.debug(log), LogLevel.Debug, cause, message, stackTrace, value);
  }

  public info(cause: string, value: any = undefined, message = '', stackTrace = ''): void {
    this.log(log => console.info(log), LogLevel.Info, cause, message, stackTrace, value);
  }

  public warn(cause: string, value: any = undefined, message = '', stackTrace = ''): void {
    this.log(log => console.warn(log), LogLevel.Warning, cause, message, stackTrace, value);
  }

  public error(cause: string, value: any = undefined, message = '', stackTrace = ''): void {
    //this.log(log => console.error(log), LogLevel.Error, cause, message, stackTrace, value);
    console.error(value, stackTrace);
  }

  private log(
    func: (data: string) => void,
    level: LogLevel,
    cause: string,
    message: string,
    stackTrace: string,
    value: any = undefined): void {

    const source = this.location instanceof PathLocationStrategy ? this.location.path() : '';
    let exceptionMessage = message;
    if (value) {
      exceptionMessage = exceptionMessage.concat(JSON.stringify(value));
    }
    const logDto: ILog = {
      Cause: cause,
      ExceptionMessage: exceptionMessage,
      StackTrace: stackTrace,
      Url: source
    };

    if (level >= Logger.level) {
      if (level >= LogLevel.Warning && this.environment.serverLogEnabled()) {
        this.serverLog(logDto)
          .pipe(untilDestroyed(this))
          .subscribe(
            () => console.warn(logDto),
            () => console.error(logDto)
          );
      } else {
        func(logDto.Cause + ' ' + logDto.ExceptionMessage);
      }
    }
  }

  private serverLog(logDto: ILog): Observable<any> {
    const url = this.url.buildServerApiUrl('log');

    return this.http.post(url, logDto);
  }
}
