import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UrlService } from 'app/core/http/url.service';
import { Observable } from 'rxjs';

import { IVideoEventModel, IVideoModel } from './video-with-events/models';

@Injectable({
  providedIn: 'root'
})
export class VideoDataService {

  public constructor(
    private readonly http: HttpClient,
    private readonly urlService: UrlService) {
  }

  public getVideo(videoId: string): Observable<IVideoModel> {
    return this.http.get<IVideoModel>(
      this.urlService.buildServerApiUrl('Video', videoId)
    );
  }

  public saveVideoEvent(videoId: string, event: IVideoEventModel): Observable<void> {
    return this.http.post<void>(
      this.urlService.buildServerApiUrl('Video', videoId, 'Event'),
      event
    );
  }

  public rateVideo(videoId: string, rating: number, attemptId: string, questionAttemptId: string): Observable<void> {
    return this.http.post<void>(
      this.urlService.buildServerApiUrl('Video', videoId, 'Rating'),
      {
        Rating: rating,
        AttemptId: attemptId,
        QuestionAttemptId: questionAttemptId
      });
  }
}
