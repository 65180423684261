import { ChangeDetectorRef, Component, ElementRef, Input, Output, ViewChild, Inject } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

import { UntilDestroy } from '@ngneat/until-destroy';
import { OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { PuzzleEventService } from '../services/puzzle-event.service';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../services/IQuestionEvents';

@UntilDestroy()
@Component({
  selector: 'kh-puzzle-overlay',
  styleUrls: ['./puzzle-overlay.component.less'],
  templateUrl: './puzzle-overlay.component.html'
})
export class PuzzleOverlayComponent implements OnDynamicMount {
  private static sequentialId = 0;
  private static focusedId = 0;
  private static overlayCount = 0;

  @Output() public onPlayClick: any;
  // sometimes, like when running a teacher gameshow or running in report mode - we never want them to be able to submit no matter what
  @Input() public disablePuzzleSubmit = false;
  @ViewChild('playButton') public playButton: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('overlayDiv') public overlayDiv: ElementRef<HTMLDivElement> | undefined;
  public myId = 0;
  public isPlaying$ = this.puzzleEventService.isPlaying;
  public isDisplayingResults$ = this.puzzleEventService.isDisplayingResults;
  public hasClickedSubmit = false;
  private puzzleDiv: HTMLElement | null = null;
  // private overlayDiv: HTMLDivElement | null = null;
  private resizeObservable$: Observable<Event> | null = null;
  private subscriptions: Subscription[] = [];
  // private resizeSubscription$: Subscription | null = null;

  public constructor(
    @Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService,
    public puzzleEventService: PuzzleEventService,
    private changeDetector: ChangeDetectorRef) {
    this.myId = ++PuzzleOverlayComponent.sequentialId;
  }

  public ngOnInit(): void {
    ++PuzzleOverlayComponent.overlayCount;

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.subscriptions.push(this.resizeObservable$.subscribe(event => this.detectScale()));
    this.subscriptions.push(this.puzzleEventService.isCorrect$.subscribe(this.detectChanges.bind(this)));
    this.subscriptions.push(this.puzzleEventService.isPlaying$.subscribe(this.detectChanges.bind(this)));
    this.subscriptions.push(this.puzzleEventService.isDisplayingResults$.subscribe(this.detectChanges.bind(this)));
    this.subscriptions.push(this.puzzleEventService.disablePlay$.subscribe(this.detectChanges.bind(this)));
  }

  public ngOnDestroy(): void {
    --PuzzleOverlayComponent.overlayCount;

    for (let i = 0; i < this.subscriptions.length; i++) {
      if (this.subscriptions[i]) {
        this.subscriptions[i].unsubscribe();
      }
    }

    this.subscriptions = [];

    this.puzzleDiv = null;

    this.puzzleEventService?.isDisplayingResults.next(false);
    this.puzzleEventService?.isPlaying.next(false);
    this.puzzleEventService?.showNextButton.next(false);
    this.puzzleEventService?.isPuzzle.next(false);
  }

  public ngAfterViewInit(): void {
    this.puzzleDiv = document.getElementById(`puzzleSvgContainer${this.myId}`);
    this.detectScale();
  }

  public ngAfterViewChecked(): void {
    this.detectScale();
  }

  private detectChanges(): void {
    this.changeDetector.detectChanges();
  }

  public detectScale(): void {
    if (this.puzzleDiv) {
      const currentPuzzleWidth = this.puzzleDiv.offsetWidth;
      const currentPuzzleHeight = this.puzzleDiv.offsetHeight;

      // assume a target resolution of 720x1280px for a baseline
      const curScale = currentPuzzleWidth / 1280;
      if (this.overlayDiv) {

        this.overlayDiv.nativeElement.style.scale = curScale.toString();
      }
    }
  }

  public onSubmitClicked(): void {
    this.hasClickedSubmit = true;
    this.questionEventsService.puzzleSubmitButtonClicked();
  }

  public onTryAgainClicked(): void {
    this.puzzleEventService.isDisplayingResults.next(false);
    this.puzzleEventService.puzzleTryAgainClicked();
  }

  public onDisabledSubmitClicked(): void {
    if (!this.hasClickedSubmit && !this.isPlaying$.value) {
      this.puzzleEventService.puzzleHelpClicked();
    }
  }

  public onReplayClicked(): void {
    this.puzzleEventService.isDisplayingResults.next(false);
    this.puzzleEventService.puzzleReplayButtonClicked();
  }

  public onPlayClicked(): void {
    if (!this.isPlaying$.value) {

      this.playButton?.nativeElement.addEventListener('transitionend', () => {
        this.puzzleEventService.puzzlePlayButtonClicked();
      });

      this.playButton?.nativeElement.setAttribute('class', 'puzzlePlayButton shrink');
    }
  }

  public onDynamicMount(data: OnDynamicData): void {
    this.puzzleEventService.awaitingPixiInitialize.next(true);
  }

  public shouldDisplayTryAgain(): boolean {
    return !this.isPlaying$.value && this.isDisplayingResults$.value && !this.puzzleEventService.showNextButton.value;
  }

  public shouldShowSubmit(): boolean {
    return this.isPuzzleAnswerCorrect() && !this.shouldShowDisabledSubmit();
  }

  public isPuzzleAnswerCorrect(): boolean {
    return this.puzzleEventService.showNextButton.getValue() && !this.disablePuzzleSubmit;
  }

  public shouldShowResetCorrect(): boolean {
    return this.isDisplayingResults$.value && this.puzzleEventService.isCorrect.value;
  }

  public shouldShowResetIncorrect(): boolean {
    return this.isDisplayingResults$.value && !this.puzzleEventService.isCorrect.value;
  }

  public shouldShowPlayButton(): boolean {
    return !this.isPlaying$.value && !this.isDisplayingResults$.value && !this.puzzleEventService.disablePlay.value;
  }

  public shouldShowDisabledSubmit(): boolean {
    return this.hasClickedSubmit || !this.isPuzzleAnswerCorrect();
  }

  public awaitingPixiInitialize(): boolean {
    const val = PuzzleOverlayComponent.focusedId !== this.myId
    this.puzzleEventService.awaitingPixiInitialize.next(val);
    const isPixi = this.puzzleEventService.isPixi.getValue();

    if(PuzzleOverlayComponent.overlayCount > 1) {
      return val && isPixi;
    } else if (isPixi) {
      this.pixiInputClicked();
      return false;
    }
    
    return false;
  }

  public pixiInputClicked(): void {
    PuzzleOverlayComponent.focusedId = this.myId;
    this.puzzleEventService.awaitingPixiInitialize.next(false);
  }
}
