import { Component, Inject, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faIcons } from 'app/core/app-constants/used-fa-icons';
import { filter, tap } from 'rxjs/operators';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { LOCALE_CONFIG, TEMPLATE_PREFIX } from 'env/locale-config';
import { ThemesService } from 'app/core/themes/themes.service';
import { TranslationService } from 'app/core/internationalization/translation.service';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, Scroll } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT, ViewportScroller } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'app.component.html'
})

export class AppComponent implements OnInit {
  public title = 'Web App';
  public themeUrl?: SafeResourceUrl;

  public constructor(
    private readonly translationService: TranslationService,
    private readonly sanitizer: DomSanitizer,
    private readonly themesService: ThemesService,
    @Inject(DOCUMENT) private document: Document,
    library: FaIconLibrary,
    router: Router,
    viewportScroller: ViewportScroller) {

    this.translationService.initializeTranslatation();
    this.translationService.switchLanguage(LOCALE_CONFIG.APP_LOCALE);

    library.addIcons(...faIcons as IconDefinition[]);

    router.events
      .pipe(
        filter((event): event is Scroll => event instanceof Scroll),
        untilDestroyed(this))
      .subscribe(e => {
        if (!router.getCurrentNavigation()?.extras?.state?.suppressScroll) {
          viewportScroller.scrollToPosition(e.position ?? [0, 0]);
        }
      });
  }

  public ngOnInit(): void {
    this.document.documentElement.lang = LOCALE_CONFIG.APP_LOCALE.split('-')[0];

    this.themesService.themeChanged()
      .pipe(
        tap(theme => this.themeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(theme)),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
