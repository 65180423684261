import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { IConfirmDialogData } from './confirm-dialog-data';

import { TEMPLATE_PREFIX } from 'env/locale-config';

@Component({
  selector: 'kh-confirm-dialog',
  styleUrls: ['./confirm-dialog.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements IConfirmDialogData {

  public title = '';
  public body = '';
  public firstButtonText = '';
  public secondButtonText = '';

  public firstButtonHandler(): void {
    // do nothing. That is a placeholder.
  }

  public secondButtonHandler(): void {
    // do nothing. That is a placeholder.
  }

  public constructor(public readonly bsModalRef: BsModalRef) { }

  public close(): void {
    this.bsModalRef.hide();
  }

  public onFirstButtonClick(): void {
    this.close();
    this.firstButtonHandler();
  }

  public onSecondButtonClick(): void {
    this.close();
    this.secondButtonHandler();
  }
}
