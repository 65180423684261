/* eslint-disable sort-keys-fix/sort-keys-fix */
// NOTE: name should be lowercase as lowercase serializer is used.
export const ADMIN_ROUTE = {
  name: 'admin',
  route(): string[] {
    return ['/' + this.name];
  },

  BOARD: {
    name: 'board/:boardid',
    route(boardId: string): string[] {
      return [...ADMIN_ROUTE.route(), 'board', boardId];
    }
  },
  CLASS: {
    name: 'class/:classid',
    route(classId: string): string[] {
      return [...ADMIN_ROUTE.route(), 'class', classId];
    }
  },
  USER: {
    name: 'user/:userName',
    route(userName: string): string[] {
      return [...ADMIN_ROUTE.route(), 'user', userName];
    }
  },
  EDIT_TEACHER_STUDENTS: {
    name: 'editteacherstudents/:teacherid',
    route(teacherId: string): string[] {
      return [...ADMIN_ROUTE.route(), 'editteacherstudents', teacherId];
    }
  },
  MAIN: {
    name: 'main',
    route(): string[] {
      return [...ADMIN_ROUTE.route(), this.name];
    }
  },
  MATHALON_ADMIN: {
    name: 'mathalonadmin',
    route(): string[] {
      return [...ADMIN_ROUTE.route(), this.name];
    }
  },
  MERGE_BOARDS: {
    name: 'mergeboards',
    route(): string[] {
      return [...ADMIN_ROUTE.route(), this.name];
    }
  },
  PREMIUM_CODES: {
    name: 'premiumcodes',
    route(): string[] {
      return [...ADMIN_ROUTE.route(), this.name];
    }
  },
  SCHEDILED_TASKS: {
    name: 'scheduledtasks',
    route(): string[] {
      return [...ADMIN_ROUTE.route(), this.name];
    }
  },
  TRANSLATIONS_ABSOLUTE: {
    name: '/admin/translations',
    route(): string[] {
      return [...ADMIN_ROUTE.route(), ADMIN_ROUTE.TRANSLATIONS.name];
    }
  },
  TRANSLATIONS: {
    name: 'translations',
    route(): string[] {
      return [this.name];
    }
  },
  TRANSLATIONS_UI: {
    name: 'ui',
    route(): string[] {
      return [...ADMIN_ROUTE.TRANSLATIONS.route(), this.name];
    }
  },
  TRANSLATIONS_RESOURCES: {
    name: 'resource',
    route(): string[] {
      return [...ADMIN_ROUTE.TRANSLATIONS.route(), this.name];
    }
  }
};
