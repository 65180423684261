import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as ES_MX_TRANSLATE from '../../../assets/translate/es-mx.translate.json';
import * as FR_CA_TRANSLATE from '../../../assets/translate/fr-ca.translate.json';

import { LANGUAGE } from './language';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  public static readonly DEFAULT_LANGUAGE = LANGUAGE.ENGLISH_CANADA;

  public constructor(private readonly translate: TranslateService) { }

  public initializeTranslatation(): void {
    this.translate.setTranslation(LANGUAGE.FRENCH_CANADA, FR_CA_TRANSLATE);
    this.translate.setTranslation(LANGUAGE.SPANISH_MEXICO, ES_MX_TRANSLATE);

    this.translate.setDefaultLang(TranslationService.DEFAULT_LANGUAGE);
  }

  public getCurrentLanguage() : LANGUAGE {
    return this.translate.currentLang as LANGUAGE;
  }

  public switchLanguage(languageValue: string): void {
    const language = languageValue as LANGUAGE;
    if (!language) {
      this.translate.use(TranslationService.DEFAULT_LANGUAGE);
    } else {
      this.translate.use(language);
    }
  }
}
