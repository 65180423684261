import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from "@angular/router";

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const shouldReuse = super.shouldReuseRoute(future, curr);
    if (shouldReuse) {
      let route: ActivatedRouteSnapshot | null = future;
      do {
        if (route.data?.disableReuseRoute) {
          return false;
        }
        route = route.firstChild;
      } while (route);
      return true;
    }
    return shouldReuse;
  }
}