import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IDeviceInfoModel } from '../models/device-info.model';

@Injectable()
export class WebRtcInfoService {
  private webRTCAvailable: boolean = false;
  private getDeviceInfo$ = new Subject<IDeviceInfoModel>();

  constructor() {
    const getUserMedia = (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
      // @ts-ignore  
      || navigator.getUserMedia
      // @ts-ignore
      || navigator.webkitGetUserMedia
      // @ts-ignore
      || navigator.mozGetUserMedia;

    this.webRTCAvailable = !!getUserMedia;

    if (this.webRTCAvailable && typeof navigator.mediaDevices === "object") {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          let hasAudio = false;
          let hasVideo = false;

          let devicesLength = devices.length;
          for (let i = 0; i < devicesLength; i++) {
            if (devices[i].kind === "videoinput") {
              hasVideo = true;
            } else if (devices[i].kind === "audioinput") {
              hasAudio = true;
            }
          }

          this.getDeviceInfo$.next({
            hasVideo: hasVideo,
            hasAudio: hasAudio
          });
        });

        return;
    }

    this.getDeviceInfo$.next({
      hasVideo: false,
      hasAudio: false
    });
  }

  public isAvailable(): boolean {
    return this.webRTCAvailable;
  }

  public getDeviceInfo(): Observable<IDeviceInfoModel> {
    return this.getDeviceInfo$;
  }
}
