import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, Output } from '@angular/core';
import { UploadSourceType } from '../../enums/upload-source-type.enum';
import { IUploadSourceModel } from 'app/shared/models/upload-source.model';

import { TEMPLATE_PREFIX } from 'env/locale-config';

@Component({
  selector: 'kh-select-upload-source',
  styleUrls: ['./select-upload-source.component.less'],
  templateUrl: TEMPLATE_PREFIX + 'select-upload-source.component.html'
})
export class SelectUploadSourceComponent {
  @Output() public closed = new EventEmitter<IUploadSourceModel>();

  public constructor(
    private readonly bsModalRef: BsModalRef) {
  }

  public cancel(): void {
    this.bsModalRef.hide();
  }

  public useWebCam(): void {
    this.closed.emit({ source: UploadSourceType.WebCam, files: null });
    this.bsModalRef.hide();
  }

  public uploadFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    this.closed.emit({ source: UploadSourceType.File, files: files });
    this.bsModalRef.hide();
  }
}
