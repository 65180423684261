import { Component, Input } from "@angular/core";
import { DynamicContentChild, OnDynamicData, OnDynamicMount } from "ngx-dynamic-hooks";
@Component({
  selector: "kh-manipulative-window",
  templateUrl: "kh-manip-scorable-window.component.html",
  styleUrls: ["kh-manip-scorable-window.component.less"],
})
export class KhManipulativeWindowComponent implements OnDynamicMount {
  @Input("key") public key: string = "";
  @Input("id") public id: string = "";
  @Input("controllers") public controllers: string = '';
  @Input("start-id") public startID!: number;
  @Input("index") public index!: number;
  @Input("value") public value!: string;
  @Input("frame") public frame = "0 0 100 100";
  @Input("image") public image?: string;
  public contentChild!: DynamicContentChild[];

  public onDynamicMount(data: OnDynamicData): void {
    const contentChildren: DynamicContentChild[] = data.contentChildren ?? [];
    this.contentChild = contentChildren;
    contentChildren.forEach((c, index) => {
      const curComponent = c.componentRef.instance;
      curComponent.frame = this.frame;
    });
  }

}
