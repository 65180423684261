import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
//TODO: Handle this later
/*import { WatchVideoComponent } from 'app/shared/modals/watch-video/watch-video.component';*/
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { IConfirmDialogData } from '../confirm-dialog/confirm-dialog-data';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { IInputDialogData } from '../input-dialog/input-dialog-data';
import { InputDialogComponent } from '../input-dialog/input-dialog.component';
import { ModalModel } from 'app/core/modals/services/modal.model';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public constructor(
    private readonly modalService: BsModalService,
    private readonly translateService: TranslateService) { }

  public displayModal<TData>(component: any, data: Partial<TData> = {}, size = 'md', allowEscape: boolean | 'static' = 'static', backdrop : boolean | 'static' = false): BsModalRef<TData> {
    return this.modalService.show(component, {
      backdrop: allowEscape || backdrop,
      ignoreBackdropClick: !allowEscape,
      class: `modal-${size}`,
      initialState: data,
      keyboard: allowEscape === 'static' ? false : allowEscape
    });
  }

  public displayCustomModal<TData>(component: any, options?: ModalModel<TData>): BsModalRef<TData> {
    const size = options?.size ? `modal-${options.size}` : 'modal-md';

    return this.modalService.show(component, {
      backdrop: options?.backdrop ?? true,
      ignoreBackdropClick: options?.ignoreBackdropClick ?? false,
      class: `${size} ${options?.className ?? ''}`,
      initialState: options?.data,
      keyboard: options?.keyboard ?? true,
    });
  }

  public displayTwoButtonConfirm(
    title: string,
    message: string,
    firstButtonText: string,
    secondButtonText: string,
    firstButtonHandler: () => void
  ): void {
    this.displayModal<IConfirmDialogData>(ConfirmDialogComponent, {
      body: this.translateService.instant(message),
      firstButtonHandler,
      firstButtonText,
      secondButtonText,
      title
    } as IConfirmDialogData, 'sm', true);
  }

  public displayYesNoConfirm(title: string, message: string, yesCallback: () => void): void {
    this.displayTwoButtonConfirm(title, message, 'Yes', 'No', yesCallback);
  }

  public displayYesNoConfirmWithCallbacks(title: string, message: string, yesCallback: () => void, noCallback: () => void): void {
    this.displayModal<IConfirmDialogData>(ConfirmDialogComponent, {
      body: this.translateService.instant(message),
      firstButtonHandler: yesCallback,
      secondButtonHandler: noCallback,
      firstButtonText: 'Yes',
      secondButtonText: 'No',
      title: title
    } as IConfirmDialogData, 'sm', true);
  }

  public displayLeaveCancel(title: string, message: string, LeaveCallback: () => void, CancelCallback: () => void): void {
    this.displayModal<IConfirmDialogData>(ConfirmDialogComponent, {
      body: this.translateService.instant(message),
      firstButtonHandler: LeaveCallback,
      firstButtonText: 'Leave',
      secondButtonText: 'Cancel',
      secondButtonHandler: CancelCallback,
      title: title
    } as IConfirmDialogData, 'sm', true);
  }


  public displayInputModal(title: string, message: string, yesCallback: (value: string) => void): void {
    this.displayModal<IInputDialogData>(InputDialogComponent, {
      body: this.translateService.instant(message),
      firstButtonHandler: yesCallback,
      firstButtonText: 'Submit',
      secondButtonText: 'Cancel',
      title: title
    } as IConfirmDialogData, 'sm', true);
  }

  //TODO: Handle this later
  /*public showVideo(videoId: string, titile = '', description = '') : void {
    this.displayModal<WatchVideoComponent>(WatchVideoComponent, {
      description: description,
      title: titile,
      videoId: videoId
    }, 'lg', true);
  }*/
}
