import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[khClickOutside]'
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Input() khClickOutside!: () => any;
  @Input() isEventPrevented = true;

  public constructor(private readonly element: ElementRef,
                     @Inject(DOCUMENT) private document: Document) {}

  public ngOnInit(): void {
    this.document.body.addEventListener('mousedown', this.clickHandler);
  }

  public ngOnDestroy(): void {
    this.document.body.removeEventListener('mousedown', this.clickHandler);
  }

  private clickHandler = (event: MouseEvent) => {
    if (!event.target) {
      return;
    }

    const children: HTMLElement[] = this.getChildrenOfElement(this.element?.nativeElement);
    const isClickedElementChildOfPopup = children.includes(event.target as HTMLElement);

    if (isClickedElementChildOfPopup) {
      return;
    }

    this.khClickOutside();

    if (this.isEventPrevented) {
      event.preventDefault();
    }
  }

  private getChildrenOfElement(element: HTMLElement): HTMLElement[] {
    if (!element.children) {
      return [element];
    }

    const children: any[] = [].map.call(element.children, (child: HTMLElement) => {
      const innerChildren: HTMLElement[] = !child.children.length ? [] : this.getChildrenOfElement(child);

      return [child, ...innerChildren];
    });

    // @ts-ignore
    return children.flat(Infinity);
  }
}
